<template>
    <input :id="idInput" type="tel" v-bind="$attrs" v-model="telValue"  @input="onInput" />
</template>
<script>
import 'intl-tel-input/build/css/intlTelInput.min.css'
import intlTelInput from 'intl-tel-input'
import utils from 'intl-tel-input/build/js/utils'

import { nanoid } from "nanoid"

export default {
  props: {
    inputData: String,
  },
  data () {
    return {
      idInput: nanoid(),
      telValue: '',
      isOpen: false,
      local: {
        input: null,
        iti: null,
        countryData: [],
        select: null,
      },
    }
  },
  watch: {
    inputData: {
      immediate: true,
      deep: true,
      // eslint-disable-next-line no-unused-vars
      handler (newValue, oldValue) {
        this.telValue = this.inputData
        if (this.local.iti && this.local.iti !== null) {
          this.local.iti.setNumber(this.telValue)
        }
      },
    },
  },
  created () {
    if (this.inputData) {
      this.telValue = this.inputData
    }
  },
  mounted () {
    // inicializa los controles
    // console.log('$attrs ', this.$attrs)
    const id = this.$attrs.id || this.idInput
    this.local.input = document.querySelector(`#${id}`)
    const geoIpFunc = this.geoIpLookup
    // busqueda por geoIp
    this.local.iti = intlTelInput(this.local.input, {
      initialCountry: "auto",
      nationalMode: false,
      autoPlaceholder: 'off',
      geoIpLookup: geoIpFunc,
      utilsScript: utils, // "../../build/js/utils.js?1613236686837" // just for formatting/placeholders etc
      // eslint-disable-next-line object-shorthand
      customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
        return selectedCountryPlaceholder
      },
      allowDropdown: true,
      autoHideDialCode: false,
      formatOnDisplay: false,
      customContainer: 'mr-1',
      // separateDialCode: true,
    })

    const onChangeCountryFunc = this.onChangeCountry
    this.local.input.addEventListener('countrychange', onChangeCountryFunc)

    this.local.countryData = window.intlTelInputGlobals.getCountryData()
    // console.log(countryData.length)
    if (this.$mq === 'mobile') {
      this.local.select = document.createElement('select')
      for (let i = 0; i < this.local.countryData.length; i += 1) {
        const country = this.local.countryData[i]
        const optionNode = document.createElement("option")
        optionNode.value = country.iso2
        const textNode = document.createTextNode(country.name)
        optionNode.appendChild(textNode)
        this.local.select.appendChild(optionNode)
      }
      // set it's initial value
      this.local.select.value = this.local.iti.getSelectedCountryData().iso2
    }
  },
  methods: {
    geoIpLookup (callback) {
      // console.log('entra geoIp')
      const xmlHttp = new XMLHttpRequest()
      xmlHttp.open("GET", 'https://ip2c.org/s', false) // false for synchronous request
      xmlHttp.send(null)
      const resp = xmlHttp.responseText
      const parseCountry = resp.split(';')
      const countryCode = (resp && parseCountry[1]) ? parseCountry[1].toLowerCase() : "us"
      // console.log('country', countryCode, resp)
      callback(countryCode)
    },
    onInput () {
      this.$emit('input', this.telValue)
      this.$emit('update:inputData', this.telValue)
      this.$emit('context', this.local.iti.getNumber(), this.local.iti.isValidNumber(), this.local.iti.getSelectedCountryData().dialCode)
    },
    onChangeCountry (e) {
      e.preventDefault()
      // const cD = this.local.iti.getSelectedCountryData()
      const num = this.local.iti.getNumber()
      // console.log(cD, num)
      this.local.iti.setNumber(num)
      this.telValue = this.local.iti.getNumber()
      this.onInput()
    },
  },
}
</script>
