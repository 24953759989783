<template>
  <!-- Begin page -->
  <div class="accountbg">
    <div class="wrapper-page">
      <div class="card card-pages shadow-none" :class="temaVisual === 'dark' ? 'is-dark' : ''">
        <div class="card-body">
          <div class="text-center m-t-0 m-b-15">
            <a href="#" class="logo logo-admin">
              <img :src="temaVisual === 'dark' ? urlLogoDark : urlLogo" style="max-height: 100px;" alt="" height="100"/>
            </a>
          </div>
          <h5 class="font-18 text-center">{{ $t('login.title') }}</h5>
          <b-alert 
            :show="!!msgAlert && msgAlert.length > 0" 
            variant="danger" 
            dismissible 
            fade
            @dismissed="msgAlert = ''"
          >
            {{ msgAlert }}
          </b-alert>
          <form class="form-horizontal m-t-30" @submit.prevent="onSubmit">
            <div class="form-group" :class="temaVisual === 'dark' ? 'is-dark' : ''">
              <div class="col-12">
                <label>{{ $t('login.username') }}</label>
                <input
                  :name="$t('login.username')"
                  v-model="model.email"
                  class="form-control"
                  :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''"
                  type="email"
                  placeholder=""
                  required
                />
              </div>
            </div>

            <div class="form-group" :class="temaVisual === 'dark' ? 'is-dark' : ''">
              <div class="col-12">
                <label>{{ $t('login.password') }}</label>
                <div class="input-group">
                  <input
                    :name="$t('login.password')"
                    v-model="model.password"
                    class="form-control"
                    :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''"
                    :type="showPassword ? 'text' : 'password'"
                    placeholder=""
                    required
                  />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" @click="togglePassword"><i :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i></button>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group text-center m-t-20" :class="temaVisual === 'dark' ? 'is-dark' : ''">
              <div class="col-12">
                <button
                  class="is-dark btn btn-primary btn-block btn-lg waves-effect waves-light"
                  type="submit"
                >
                  {{ $t('login.enter') }}
                </button>
              </div>
            </div>

            <div class="form-group row m-t-30 m-b-0" :class="temaVisual === 'dark' ? 'is-dark' : ''">
              <div class="col-sm-7">
                <a class="text-muted" @click.prevent="recuperarClave" style="cursor: pointer;">
                  <i class="fa fa-lock m-r-5"></i> {{ $t('login.forgotPassword') }}
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- END wrapper -->
  </div>
</template>

<script>
import md5 from 'js-md5'
import { nanoid } from 'nanoid'
import { mapMutations, mapGetters } from 'vuex'
import Cookie from 'js-cookie'

export default {
  data () {
    return {
      msgAlert: '',
      model: {
        email: '',
        password: '',
        rememberMe: false,
      },   
      showPassword: false,
    }
  },
  computed: {
    ...mapGetters({
      urlLogo: 'getUrlLogo',
      urlLogoDark: 'getUrlLogoDark',
      urlImagenes: 'getUrlImagenes',
      urlClave: 'getUrlClave',
      temaVisual: 'getTemaVisual',
    }),
  },
  methods: {
    ...mapMutations([
      'setUsuario',
    ]),
    async onSubmit () {
      const reglasPassword = [(v) => !!v || this.$t('login.passwordRequired')]
      const reglasEmail = [
        (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('login.emailValid'),
      ]
      let validEmail = ''
      reglasEmail.forEach((rule) => {
        validEmail = rule(this.model.email)
      })
      if (validEmail !== true) {
        this.msgAlert = validEmail
        return
      }
      let validPassword = ''
      reglasPassword.forEach((rule) => {
        validPassword = rule(this.model.password)
      })
      if (validPassword !== true) {
        this.msgAlert = validPassword
        return
      }

      const token = nanoid() 
      const hash = md5.create()
      hash.update(this.model.password)

      try {
        const response = await this.$api
          .ejecutar('login', [token, this.model.email, hash.hex()], 0)
        
        if (response.length > 0) {
          if (response[0].status !== null && response[0].status === 'ERROR') {
            this.msgAlert = response[0].message
            return
          } 
          const user = {
            ...response[0],
            token,
          }
          this.setUsuario(user)
          Cookie.set('btapUsrTk', token)
          this.$router.push('/dashboard')
        }
      } catch (e) {
        this.msgAlert = this.$t('errors.networkError')
      }
    },
    togglePassword () {
      this.showPassword = !this.showPassword
    },
    async recuperarClave () {
      if (!this.model.email || this.model.email === null || this.model.email.length <= 0) {
        this.msgAlert = this.$t('login.emailIsBlank')
        return
      }
      // revisa si el correo existe en la DB
      const tokenTemporal = nanoid()
      const user = await this.$api.ejecutar('existe_usr', [tokenTemporal, this.model.email])
      if (!!user && !!user[0] && user[0].status === 'ERROR') {
        this.msgAlert = this.$t('login.emailNotExist')
        return
      } 
      if (!!user && !!user[0] && user[0].status === 'OK') {
        // se envía el correo al usuario para cambiar clave
        const datosCorreo = {
          welcomeTitle: this.$t('users.mailRecoverTitle'),
          message: this.$t('users.mailRecoverMessage'),
          actionTitle: this.$t('users.mailRecoverActionTitle'),
          urlAction: `${this.urlClave}/${tokenTemporal}`, 
          _correo: this.model.email,
          _asunto: this.$t('users.mailRecoverSubject'),
        }
        // console.log('datos correo ', datosCorreo)
        const responseMail = await this.$api.notify(datosCorreo)
        this.msgAlert = this.$t('users.mailRecoverSent')
      }
    },
  },
}
</script>
