<template>
  <!-- Start content -->
  <div class="content">
    <div class="container-fluid">
      <div class="page-title-box">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <h4 class="page-title">Dashboard</h4>
          </div>
          <!-- <div class="col-sm-6">
            <ol class="breadcrumb float-right">
              <li class="breadcrumb-item">
                <a href="javascript:void(0);">Stexo</a>
              </li>
              <li class="breadcrumb-item active">Dashboard</li>
            </ol>
          </div> -->
        </div>
        <!-- end row -->
      </div>
      <!-- end page-title -->

      <!-- Totales -->
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <!-- <div class="card" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''">
            <div class="card-heading p-4">
              <div class="mini-stat-icon float-right">
                <i class="mdi mdi-cube-outline bg-primary text-white"></i>
              </div>
              <div>
                <h5 class="font-16">Active Sessions</h5>
              </div>
              <h3 class="mt-4">43,225</h3>
              <div class="progress mt-4" style="height: 4px">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  style="width: 75%"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p class="text-muted mt-2 mb-0">
                Previous period<span class="float-right">75%</span>
              </p>
            </div>
          </div> -->

          <b-card no-body class="overflow-hidden p-2" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : 'shadow rounded'" style="border-radius: 15px !important;">
            <b-row>
              <b-col xs="7">
                <b-row>
                  <b-col>
                    <img :src="temaVisual === 'dark' ? urlLogoDark : urlLogo" alt="BTap" class="rounded-0 p-1" width="100">
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    
                  </b-col>
                </b-row>
              </b-col>
              <b-col xs="5">
                <button
                  class="btn btn-secondary btn-block no-border text-white"
                  size="md"
                  @click="descargarQR(dispositivo)"
                >
                  <!-- <i class="fa fa-qrcode mr-3" v-b-tooltip.hover :title="$t('devices.downloadQR')" ></i> -->{{ $t('devices.downloadQR') }}
                </button>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="ml-2 mr-3">
                <b-form-select 
                      id="dropdown-dispositivos" 
                      :text="dispositivo.alias ? dispositivo.alias : dispositivo.id_dispositivo" 
                      class="m-1 mr-2 h6 block custom-control" 
                      :class="temaVisual === 'dark' ? 'is-dark text-muted  bg-transparent' : 'is-light bg-transparent'"
                      v-model="dispositivo"
                      @input="seleccionaDispositivo"
                    >
                      <b-form-select-option 
                        v-for="item in items" 
                        :key="item.id_dispositivo" 
                        :value="item"
                        class="custom-control"
                        :class="temaVisual === 'dark' ? 'is-dark text-muted border-0 bg-transparent' : 'is-light bg-transparent'"
                      >
                        {{ item.alias ? item.alias : item.id_dispositivo }}
                      </b-form-select-option>
                    </b-form-select>
                    <h6>{{ dispositivo.id_accion_principal ? $t(`devices.actionsLabels.${dispositivo.id_accion_principal}`) : ''}}</h6>
              </b-col>
            </b-row>
            <!-- <b-row>
              @click="seleccionaDispositivo(item)"
              <b-col sm="12">
              <i class="fa fa-clipboard fa-2x mr-3 float-right text-btap-primary" v-b-tooltip.hover :title="$t('devices.copyURL')" @click="copiarQR(dispositivo)"></i>          
              </b-col>
            </b-row> -->
          </b-card>
        </div>
      </div>
      <div class="row">
        
        <!-- Tap Totales -->
        <div class="col-sm-6 col-xl-3">
          <div class="card" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''">
            <div class="card-heading p-4">
              <div class="mini-stat-icon float-right">
                <i class="mdi mdi-briefcase-check bg-btap-primary text-white"></i>
              </div>
              <div>
                <h5 class="font-16">{{ $t('dashboard.totalTaps') }}</h5>
              </div>
              <h3 class="mt-4">{{ optionTotal }}</h3>
              <!-- <div class="progress mt-4" style="height: 4px">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  style="width: 88%"
                  aria-valuenow="88"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div> -->
              <p class="text-muted mt-2 mb-0">
                <!-- Previous period<span class="float-right">88%</span> -->
              </p>
            </div>
          </div>
        </div>
         <!-- end Totales -->

        <!-- Tap Esta Semana -->
        <div class="col-sm-6 col-xl-3">
          <div class="card" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''">
            <div class="card-heading p-4">
              <div class="mini-stat-icon float-right">
                <i class="fa fa-calendar bg-btap-primary text-white"></i>
              </div>
              <div>
                <h5 class="font-16">{{ $t('dashboard.tapsPerWeek') }}</h5>
              </div>
              <h3 class="mt-4">{{ semana }}</h3>
              <!-- <div class="progress mt-4" style="height: 4px">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  style="width: 88%"
                  aria-valuenow="88"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div> -->
              <p class="text-muted mt-2 mb-0">
                <!-- Previous period<span class="float-right">88%</span> -->
              </p>
            </div>
          </div>
        </div>
        <!-- end Semana -->

        <!-- Tap Esta mes -->
        <div class="col-sm-6 col-xl-3">
          <div class="card" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''">
            <div class="card-heading p-4">
              <div class="mini-stat-icon float-right">
                <i class="fa fa-calendar bg-btap-primary text-white"></i>
              </div>
              <div>
                <h5 class="font-16">{{ $t('dashboard.tapsPerMonth') }}</h5>
              </div>
              <h3 class="mt-4">{{ mes }}</h3>
              <!-- <div class="progress mt-4" style="height: 4px">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  style="width: 88%"
                  aria-valuenow="88"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div> -->
              <p class="text-muted mt-2 mb-0">
                <!-- Previous period<span class="float-right">88%</span> -->
              </p>
            </div>
          </div>
        </div>
        <!-- end mes -->

        <!-- Tap Mes Anterior -->
        <div class="col-sm-6 col-xl-3">
          <div class="card" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''">
            <div class="card-heading p-4">
              <div class="mini-stat-icon float-right">
                <i class="fa fa-calendar bg-btap-primary text-white"></i>
              </div>
              <div>
                <h5 class="font-16">{{ $t('dashboard.tapsPerPreviousMonth') }}</h5>
              </div>
              <h3 class="mt-4">{{ mesAnterior }}</h3>
              <!-- <div class="progress mt-4" style="height: 4px">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  style="width: 88%"
                  aria-valuenow="88"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div> -->
              <p class="text-muted mt-2 mb-0">
                <!-- Previous period<span class="float-right">88%</span> -->
              </p>
            </div>
          </div>
        </div>
        <!-- end Mes Anterior -->

        <!-- Taps por ubicacion -->
        <!-- <div class="col-sm-6 col-xl-3">
          <div class="card" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''">
            <div class="card-heading p-4">
              <div class="mini-stat-icon float-right">
                <i class="fa fa-map-marker-alt bg-btap-primary text-white"></i>
              </div>
              <div>
                <h5 class="font-16">{{ $t('dashboard.tapsPerLocation') }}</h5>
              </div>
              <ul class="list-group border-0" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none bg-transparent' : ''">
                <li class="list-group-item d-flex justify-content-between align-items-center border-0" 
                  v-for="loc in locationTaps" :key="loc"
                  :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none bg-transparent' : ''">
                  {{ loc.ubicacion }}
                  <span class="badge">{{ loc.cantidad_taps }}</span>
                </li>
              </ul>
              
            </div>
          </div>
        </div> -->
        <!-- end Taps por ubicacion -->

      </div>

      <!-- Graficas -->
      <div class="row">
        <!-- Taps por ubicacion -->
        <div class="col-md-3 col-xs-12">
            <div class="card m-b-30" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''" :style="$mq === 'mobile' ? 'max-height: 440px;' : 'height: 448px;' ">
              <div class="card-heading p-4">
                <div class="mini-stat-icon float-right">
                  <i class="fa fa-map-marker-alt bg-btap-primary text-white"></i>
                </div>
                <div>
                  <h5 class="font-16">{{ $t('dashboard.tapsPerLocation') }}</h5>
                </div>
              </div>
              <div class="card-body" :style="$mq === 'mobile' ? 'overflow-y: auto;' : 'overflow-y: auto;' ">
                <ul class="list-group border-0" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none bg-transparent' : ''">
                  <li class="list-group-item d-flex justify-content-between align-items-center border-0 pt-0" 
                    v-for="(loc, idxloc) in locationTaps" :key="idxloc"
                    :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none bg-transparent' : ''">
                    {{ loc.ubicacion }}
                    <span class="badge">{{ loc.cantidad_taps }}</span>
                  </li>
                </ul>
              </div>
            </div>
        </div>
        <!-- end Taps por ubicacion -->
        <div class="col-md-9 col-xs-12">
          <div class="card m-b-30" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''">
            <div class="card-body">
              <h4 class="mt-0 header-title mb-4">
                {{ $t("dashboard.cardStatsDate") }}
              </h4>

              <div id="chart">
                <apexchart
                  type="line"
                  height="350"
                  :options="optionChartDate"
                  :series="seriesChartDate"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-xl-8">
          <div class="card m-b-30" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''">
            <div class="card-body">
              <h4 class="mt-0 header-title mb-4">
                {{ $t("dashboard.cardStatsClient") }}
              </h4>

              <div id="chart2">
                <apexchart
                  type="bar"
                  height="350"
                  :options="optionChartType"
                  :series="seriesChartType"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-4">
          <div class="card m-b-30" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''" style="height: 93%">
            <div class="card-body">
              <h4 class="mt-0 header-title mb-4">
                {{ $t("dashboard.cardStatsActions") }}
              </h4>

              <div id="chart3">
                <apexchart
                  type="donut"
                  :options="optionsDonut"
                  :series="seriesDonut"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end Graficas -->

      <!-- Leads -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card m-b-30" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''">
            <div class="card-body">
              <div class="table-responsive">
                <b-row class="mb-2">
                  <b-col xs="12" sm="8" :class="$mq === 'mobile' ? 'text-center' : ''">
                    <h3 v-if="$mq === 'mobile'" class="mb-0 pb-0">{{ totalRows }}</h3>
                    <h4 class="header-title mt-0">
                      {{ $t("dashboard.leads") }}
                     <span v-if="$mq !== 'mobile'" style="font-size: 1.5rem !important">{{ totalRows }}</span></h4>
                  </b-col>
                  <b-col xs="12" sm="4">
                     <b-button
                      variant="primary"
                      class="float-right mb-2"
                      :block="$mq === 'mobile'"
                      @click="leadsToCSV"
                    >
                      <i class="fas fa-download"></i>
                      {{ $t("dashboard.toCSV") }}
                    </b-button>
                    
                  </b-col>
                </b-row>

                <!-- <table id="datatable-buttons" class="table table-striped table-bordered dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                  <thead>
                  <tr>
                      <th>Name</th>
                      <th>Position</th>
                      <th>Office</th>
                      <th>Age</th>
                      <th>Start date</th>
                      <th>Salary</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <td>Tiger Nixon</td>
                      <td>System Architect</td>
                      <td>Edinburgh</td>
                      <td>61</td>
                      <td>2011/04/25</td>
                      <td>$320,800</td>
                  </tr>
                  <tr>
                      <td>Garrett Winters</td>
                      <td>Accountant</td>
                      <td>Tokyo</td>
                      <td>63</td>
                      <td>2011/07/25</td>
                      <td>$170,750</td>
                  </tr>
                  
                  </tbody>
              </table> -->
                <b-table
                  ref="table"
                  :striped="striped"
                  :bordered="bordered"
                  :outlined="outlined"
                  :small="small"
                  :hover="hover"
                  :stacked="$mq === 'mobile' ? true : false"
                  :foot-clone="footClone"
                  :responsive="responsive"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :items="leads"
                  :fields="fields"
                  :dark="temaVisual === 'dark'"
                  class="table-responsive table"
                  :class="temaVisual === 'dark' ? 'bg-objects' : ''"
                  :header-row-class-name="
                    temaVisual === 'dark' ? 'bg-objects' : ''
                  "
                >
                  <template #cell()="data" >
                    <span style="width: 120px;" class="truncate-text">{{ textTruncateLead(data.value) }}</span>
                  </template>
                  <template #head()="data">
                    <div style="width: 100%; word-wrap: break-word;">{{ textTruncateLead(data.label) }}</div>
                  </template>
                </b-table>
                <div class="card no-border" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''">
                <b-row>
                  <b-col sm="4">
                    <b-form-group
                      label="Items / página"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="4"
                      label-align-sm="right"
                      label-size="sm"
                      label-for="perPageSelect"
                      class="mb-0 inline"
                    >
                      <b-form-select
                        v-model="perPage"
                        id="perPageSelect"
                        size="sm"
                        :options="pageOptions"
                        class="inline"
                        :class="temaVisual === 'dark' ? 'bg-transparent' : ''"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>

                  <b-col sm="3">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0 nav pagination  justify-content-center"
                      :class="temaVisual === 'dark' ? 'is-dark' : ''"
                    ></b-pagination>
                  </b-col>
                </b-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end Leads -->
    </div>
    <!-- container-fluid -->
  </div>
  <!-- content -->
</template>
<script>
import QRCode from 'qrcode'
import VueApexCharts from "vue-apexcharts"
import _ from 'underscore'
import moment from "moment"
import { mapGetters, mapMutations } from "vuex"

const copyToClipboard = (str) => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

const chartOptionsDate = {
  chart: {
    type: "line",
    height: 350,
    zoom: {
      enabled: false,
    },
    dropShadow: {
      enabled: true,
      enabledSeries: [0],
      top: -2,
      left: 2,
      blur: 5,
      opacity: 0.06,
    },
    stacked: true,
    animations: {
      enabled: true,
      easing: "linear",
      dynamicAnimation: {
        speed: 1000,
      },
    },
    toolbar: {
      show: false,
    },
  },
  colors: ["#8176FF", "#534D9F", "#1F1B41"],
  stroke: {
    show: true,
    curve: "smooth",
    width: 5,
  },
  markers: {
    size: 0,
    strokeColor: "#fff",
    strokeWidth: 3,
    strokeOpacity: 1,
    fillOpacity: 1,
    hover: {
      size: 6,
    },
  },
  dataLabels: {
    enabled: false,
  },
  // grid: {
  //   row: {
  //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
  //     opacity: 0.5,
  //   },
  // },
  xaxis: {
    type: "datetime",
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    title: {
      text: "Taps",
    },
    labels: {
      offsetX: 14,
      offsetY: -5,
    },
    tooltip: {
      enabled: true,
    },
  },
  fill: {
    opacity: 1,
    colors: ["#1F1B41", "#534D9F", "#8176FF"],
    type: "solid",
    // type: "gradient",
    // gradient: {
    //   gradientToColors: ["#1F1B41", "#534D9F", "#8176FF"],
    // },
  },
  tooltip: {
    x: {
      format: "dd/MM/yyyy",
    },
    y: {
      formatter (val) {
        return `${val}`
      },
    },
  },
}

const chartSeriesDate = [
  {
    name: "Cantidad Taps",
    data: [],
  },
]

const chartOptionsType = {
  chart: {
    // foreColor: "#7d7e87",
    type: "bar",
    height: 350,
    dropShadow: {
      enabled: true,
      enabledSeries: [0],
      top: -2,
      left: 2,
      blur: 5,
      opacity: 0.06,
    },
    stacked: true,
    animations: {
      enabled: true,
      easing: "linear",
      dynamicAnimation: {
        speed: 1000,
      },
    },
    toolbar: {
      show: false,
    },
  },
  colors: ["#8176FF", "#534D9F", "#1F1B41"],
  stroke: {
    show: true,
    curve: "smooth",
    width: 5,
    // colors: ["transparent"],
  },
  markers: {
    size: 0,
    strokeColor: "#fff",
    strokeWidth: 3,
    strokeOpacity: 1,
    fillOpacity: 1,
    hover: {
      size: 6,
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
      columnWidth: "55%",
      endingShape: "rounded",
      borderRadius: 4,
    },
  },
  dataLabels: {
    enabled: false,
  },
  
  xaxis: {
    categories: [],
  },
  yaxis: {
    title: {
      text: "Taps",
    },
  },
  fill: {
    opacity: 1,
    colors: ["#8176FF", "#1F1B41", "#534D9F"],
    // colors: ["#534D9F", "#1F1B41", "#8176FF"],
    type: "solid",
    // type: "gradient",
    // gradient: {
    //   gradientToColors: ["#1F1B41", "#534D9F", "#8176FF"],
    // },
  },
  tooltip: {
    // theme: "dark",
    y: {
      formatter (val) {
        return `${val}`
      },
    },
  },
}

const chartSeriesType = [
  {
    name: "Cantidad Taps",
    data: [],
  },
]

const chartOptionsDonut = {
  chart: {
    // foreColor: "#fff",
    type: "donut",
    // height: 365,
    width: '100%',
    // dropShadow: {
    //   enabled: true,
    //   enabledSeries: [0],
    //   top: -2,
    //   left: 2,
    //   blur: 5,
    //   opacity: 0.06,
    // },
    // stacked: true,
    // animations: {
    //   enabled: true,
    //   easing: "linear",
    //   dynamicAnimation: {
    //     speed: 1000,
    //   },
    // },
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
          name: {
            show: false,
          },
          value: {
            show: false,
          },
          total: {
            show: false,
          },
        },
      },
      offsetY: 20,
    },
  },
  legend: {
    show: false,
    position: 'bottom',
  },
  // colors: ["#8176FF", "#534D9F", "#1F1B41"],
  // stroke: {
  //   show: true,
  //   curve: "smooth",
  //   width: 5,
  //   // colors: ["transparent"],
  // },
  // markers: {
  //   size: 0,
  //   strokeColor: "#f4f4f4",
  //   strokeWidth: 3,
  //   strokeOpacity: 1,
  //   fillOpacity: 1,
  //   hover: {
  //     size: 6,
  //   },
  // },
  // plotOptions: {
  //   bar: {
  //     horizontal: true,
  //     columnWidth: "55%",
  //     endingShape: "rounded",
  //     borderRadius: 4,
  //   },
  // },
  dataLabels: {
    enabled: false,
  },
  labels: [],
  // xaxis: {
  //   categories: [],
  // },
  // yaxis: {
  //   title: {
  //     text: "Taps",
  //   },
  // },
  // fill: {
  //   opacity: 1,
  //   // colors: ["#8176FF", "#1F1B41", "#534D9F"],
  //   // colors: ["#534D9F", "#1F1B41", "#8176FF"],
  //   type: "solid",
  //   // type: "gradient",
  //   // gradient: {
  //   //   gradientToColors: ["#1F1B41", "#534D9F", "#8176FF"],
  //   // },
  // },
  tooltip: {
    // theme: "dark",
    y: {
      formatter (val) {
        return `${val}`
      },
    },
  },
}

const chartSeriesDonut = []
export default {
  name: "Dashboard",
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    ...mapGetters({
      temaVisual: "getTemaVisual",
      lenguaje: "getLenguaje",
      usuario: "getUsuario",
      parametrosGenerales: "getParametrosGenerales",
      urlImagenes: "getUrlImagenes",
      // urldispositivos: "gerUrlDispositivos",
      urlLogo: 'getUrlLogo',
      urlLogoDark: 'getUrlLogoDark',
      urlAcciones: 'getUrlAcciones',
    }),
  },
  watch: {
    temaVisual () {
      const optionsDate = JSON.parse(JSON.stringify(this.optionChartDate))
      const optionsType = JSON.parse(JSON.stringify(this.optionChartType))
      const optionsDonut = JSON.parse(JSON.stringify(this.optionsDonut))
      if (this.temaVisual === 'dark') {
        optionsDate.chart.foreColor = '#7d7e87'
        optionsType.chart.foreColor = '#7d7e87'
        optionsDonut.chart.foreColor = '#7d7e87'
      } else {
        optionsDate.chart.foreColor = undefined
        optionsType.chart.foreColor = undefined
        optionsDonut.chart.foreColor = undefined
      }
      this.optionChartDate = optionsDate
      this.optionChartType = optionsType
      this.optionsDonut = optionsDonut
    },
  },
  data () {
    return {
      optionChartDate: { ...chartOptionsDate },
      seriesChartDate: chartSeriesDate,
      optionChartType: { ...chartOptionsType },
      seriesChartType: chartSeriesType,
      optionsDonut: { ...chartOptionsDonut },
      seriesDonut: chartSeriesDonut,
      optionTotal: 0,
      locationTaps: [],
      items: [],
      leads: [],
      fields: [],
      msgAlert: "",
      loading: false,
      dispositivo: {},
      // table properties
      filter: null,
      striped: true,
      bordered: false,
      outlined: false,
      small: false,
      hover: true,
      dark: this.temaVisual === "dark",
      fixed: false,
      footClone: false,
      responsive: true,
      totalRows: 0,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      semana: 0,
      mes: 0,
      mesAnterior: 0,
    }
  },
  mounted () {
    this.loadData()
    window.scrollTo(0, 0)
    // eslint-disable-next-line no-undef
    // $('#datatable-buttons').DataTable({
    //   responsive: true,
    // })
  },
  methods: {
    async loadData () {
      // console.log("loading devices ")
      this.loading = true
      try {
        this.items = []
        const params = [this.usuario.token]
        const response = await this.$api.ejecutar("dispositivos_sel", params)
        this.loading = false
        if (response.length > 0) {
          if (response[0].status !== null && response[0].status === "ERROR") {
            this.msgAlert = response[0].message
            return
          }
          response.forEach(async (item) => {
            item.srcQR = await QRCode.toDataURL(`${this.urlAcciones}/${item.id_dispositivo}`, { width: 512 })
          })
          this.items = response
          this.items.forEach((dv, index) => {
            if (index === 0) {
              this.dispositivo = dv
            }
          })
          // this.dispositivo = { ...response[0] }
          // console.log('dispositivo inicial ', this.dispositivo)
          this.initCharts()
        }
      } catch (e) {
        console.log("error al consultar ", e)
        this.loading = false
        this.msgAlert = this.$t("errors.networkError")
      }
    },
    async initCharts (index) {
      // debugger
      try {
        this.loading = true
        // trae registro del dispositivo
        const registro = await this.$api.ejecutar("registro_taps_sel", [
          this.usuario.token,
          this.dispositivo.id_dispositivo,
        ])
        // const hoy = moment({
        //   h: 0,
        //   m: 0,
        //   s: 0,
        //   ms: 0,
        // })
        // console.log('Hoy', hoy, hoy.format("DD/MM/YYYY"))
        registro.forEach((reg) => {
          reg.fechaOrder = moment(reg.fecha.substring(0, 10), "YYYY-MM-DD").valueOf()
          reg.fecha = moment(reg.fechaOrder).format("DD/MM/YYYY")
        })
        // console.log("registro ", registro)

        // console.log("crea grafica por fecha")
        const totalesDate = registro.filter(
          (reg) => reg.tipo_dispositivo === "Total"
            && reg.fecha
              !== moment({
                h: 0,
                m: 0,
                s: 0,
                ms: 0,
              })
                .add(1, "days")
                .format("DD/MM/YYYY"),
        )
        const opcionesChartDate = JSON.parse(
          JSON.stringify(this.optionChartDate),
        )
        if (this.temaVisual === 'dark') {
          opcionesChartDate.chart.foreColor = '#7d7e87'
        }
        const seriesChDate = JSON.parse(JSON.stringify(this.seriesChartDate))
        // opcionesChartDate.xaxis.categories = []
        seriesChDate[0].data = []
        seriesChDate[0].name = this.$t("dashboard.tapsPerDate")
        totalesDate.forEach((total) => {
          // opcionesChartDate.xaxis.categories.push(total.fecha)
          // seriesChDate[0].data.push(total.cantidad_taps)
          seriesChDate[0].data.push([
            moment(total.fecha, "DD/MM/YYYY").valueOf(),
            total.cantidad_taps,
          ])
        })
        this.optionChartDate = opcionesChartDate
        this.seriesChartDate = seriesChDate
        // console.log("optionsDate ", opcionesChartDate)
        // console.log("seriesDate ", seriesChDate)

        // console.log("crea grafica por tipo")
        const tiposDispositivo = registro.filter(
          (reg) => reg.tipo_dispositivo !== "Total"
            && reg.fecha
              === moment({
                h: 0,
                m: 0,
                s: 0,
                ms: 0,
              })
                .add(1, "days")
                .format("DD/MM/YYYY"),
        )
        const opcionesChartType = JSON.parse(
          JSON.stringify(this.optionChartType),
        )
        if (this.temaVisual === 'dark') {
          opcionesChartType.chart.foreColor = '#7d7e87'
        }
        const seriesChType = JSON.parse(JSON.stringify(this.seriesChartType))
        // opcionesChartType.title.text = ""
        opcionesChartType.xaxis.categories = []
        seriesChType[0].data = []
        seriesChDate[0].name = this.$t("dashboard.tapsPerClient")
        tiposDispositivo.forEach((total) => {
          opcionesChartType.xaxis.categories.push(total.tipo_dispositivo)
          seriesChType[0].data.push(total.cantidad_taps)
        })
        this.optionChartType = opcionesChartType
        this.seriesChartType = seriesChType
        // console.log("opcionesType ", opcionesChartType)
        // console.log("seriesType ", seriesChType)

        // console.log(
        //   "calcula total general ",
        //   moment({
        //     h: 0,
        //     m: 0,
        //     s: 0,
        //     ms: 0,
        //   })
        //     .add(1, "days")
        //     .format("DD/MM/YYYY"),
        // )
        const grandTotal = registro.filter(
          (reg) => reg.tipo_dispositivo === "Total"
            && reg.fecha
              === moment({
                h: 0,
                m: 0,
                s: 0,
                ms: 0,
              })
                .add(1, "days")
                .format("DD/MM/YYYY"),
        )
        this.optionTotal = !!grandTotal && !!grandTotal[0] ? grandTotal[0].cantidad_taps : 0
        // console.log("total ", this.optionTotal)

        // por tiempo
        // console.log("consulta taps x tiemp dispositivo")
        this.semana = 0
        this.mes = 0
        this.mesAnterior = 0
        const times = await this.$api.ejecutar("registro_taps_x_tiempo_sel", [
          this.usuario.token,
          this.dispositivo.id_dispositivo,
        ])
        // console.log("times ", times)
        times.forEach((reg) => {
          if (reg.rango_tiempo === 'semana') {
            this.semana = reg.cantidad_taps
          } else if (reg.rango_tiempo === 'mes') {
            this.mes = reg.cantidad_taps
          } else if (reg.rango_tiempo === 'mes_anterior') {
            this.mesAnterior = reg.cantidad_taps
          }
        })
        // obtiene registro por ubicacion
        const ubicaciones = await this.$api.ejecutar("registro_taps_x_ubicacion_sel", [
          this.usuario.token,
          this.dispositivo.id_dispositivo,
        ])
        if (ubicaciones && Array.isArray(ubicaciones)) {
          this.locationTaps = ubicaciones
        } else {
          this.locationTaps = []
        }
        // obtiene registro por accion
        const regAccion = await this.$api.ejecutar("registro_taps_x_accion_sel", [
          this.usuario.token,
          this.dispositivo.id_dispositivo,
        ])
        const opcionesDona = JSON.parse(JSON.stringify(chartOptionsDonut))
        if (this.temaVisual === 'dark') {
          opcionesDona.chart.foreColor = '#7d7e87'
        }
        const seriesDona = []
        regAccion.forEach((raccion) => {
          seriesDona.push(raccion.cantidad_taps)
          opcionesDona.labels.push(this.$t(`devices.actionsLabels.${raccion.id_accion}`))
        })
        this.optionsDonut = opcionesDona
        this.seriesDonut = seriesDona
        // console.log("opcionesDonut ", opcionesDona)
        // console.log("seriesDonut ", seriesDona)

        // obtiene leads del dispositivo
        // console.log("consulta leads dispositivo")
        let result = await this.$api.catalog("obtenerCapturasDispositivo", [
          this.usuario.token,
          this.dispositivo.id_dispositivo,
        ])
        // console.log("result leads ", result)
        if (Array.isArray(result)) {
          result = _.sortBy(result, '_timestamp')
          this.leads = result
          this.totalRows = result.length
          this.fields = []
          // eslint-disable-next-line prefer-destructuring
          const fields = this.fields
          if (!!result && !!result[result.length - 1]) {
            // console.log("encabezados leads")
            Object.keys(result[result.length - 1]).forEach((field) => {
              if (field[0] !== "_" && field !== "id") {
                fields.push({ 
                  key: field, 
                  label: field, 
                  tdClass: 'text-truncate', 
                  thStyle: 'width: 150px;',
                })
              }
            })
            // console.log("fields ", fields)
          }
        }

        this.loading = false
      } catch (e) {
        // console.log("error al actualizar dashboard ", e)
        this.loading = false
      }
    },
    seleccionaDispositivo (item) {
      // console.log('item ', item)
      // this.dispositivo = item
      this.initCharts()
    },
    leadsToCSV () {
      const items = this.leads
      // console.log("items ", items)
      // eslint-disable-next-line prefer-const
      let header = Object.keys(items[items.length - 1]).filter(
        (fieldName) => fieldName !== "id" && fieldName[0] !== "_",
      )
      // console.log("header ", header)
      let csv = items.map((row) => header.map((fieldName) => `"${row[fieldName] ? row[fieldName] : ''}"`)) // JSON.stringify(row[fieldName], replacer);
      // console.log("csv ", csv)
      for (let i = 0; i < header.length; i += 1) {
        header[i] = `"${header[i]}"`
      }
      // console.log("header ", header)
      csv.unshift(header.join(","))
      csv = csv.join("\r\n")

      // Download the file as CSV
      const downloadLink = document.createElement("a")
      const blob = new Blob(["\uFEFF", csv])
      const url = URL.createObjectURL(blob)
      downloadLink.href = url
      downloadLink.download = `${this.dispositivo.id_dispositivo}.csv` // Name the file here
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    },
    descargarQR (dispositivo) {
      // nothing
      const link = document.createElement('a')
      link.href = dispositivo.srcQR
      link.download = `${dispositivo.id_dispositivo}.png`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    copiarQR (dispositivo) {
      copyToClipboard(`${this.urlAcciones}/${dispositivo.id_dispositivo}`)
    },
    textTruncateLead (value) {
      let len = 30
      if (this.$mq === 'mobile') {
        len = 16
      }
      if (value && value.length > len) {
        return `${value.substring(0, len - 1)} ...` 
      }
      return value
    },
  },
}
</script>

<style>
.table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td[role=cell] {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td[role=cell] > div {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table.b-table.b-table-stacked > tbody > tr > td {
  width: 250px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table.b-table.b-table-stacked > tbody > tr > [data-label] > div {
  width: 250px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chart {
  height: 400px;
}
/* select {
  display:flex;
  flex-direction: column;
  position:relative;
  width:250px;
  height:40px;
}

option {
  padding:0 30px 0 10px;
  min-height:40px;
  display:flex;
  align-items:center;
  background:#333;
  border-top:#222 solid 1px;
  position:absolute;
  top:0;
  width: 100%;
  pointer-events:none;
  order:2;
  z-index:1;
  transition:background .4s ease-in-out;
  box-sizing:border-box;
  overflow:hidden;
  white-space:nowrap;
  
}

option:hover {
  background:#666;
}

select:focus .option {
  position:relative;
  pointer-events:all;
} */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f4f4f4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
