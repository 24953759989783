/* eslint-disable object-shorthand */
import forge from 'node-forge'

let NProgress = null
if (process.browser) {
  // eslint-disable-next-line global-require
  NProgress = require('nprogress')
}
let axios = {}
axios = require('axios')

// const OK = SUCCESS = 200
// const ERROR = 500

let constants = {}
let urlWebApi = ''
let publicKeyString = ''

const uris = {
  upload: '/upload',
  execute: '/api/ejecutar',
  catalog: '/api/data',
  notify: '/notify', 
}

const toList = (jsonObj) => {
  if (typeof jsonObj === 'undefined') { return [] }
  // const lent = jsonObj.length
  const response = []
  Object.keys(jsonObj, (key) => {
    if (Object.prototype.hasOwnProperty.call(jsonObj, key)) {
      response.push(jsonObj[key])
    }
  })
  return response
}

const callWs = (uri, json) => {
  if (NProgress !== null) {
    if (json.loading === true) {
      NProgress.start()
    }    
  }
  return new Promise(((resolve, reject) => {
    // console.log('POST ', urlWebApi + uri, json)
    axios.post(urlWebApi + uri, json).then((response) => {
      if (NProgress !== null) {
        if (json.loading === true) {
          NProgress.done()
        }
      }
      response = response.data
      if (response.code === 200) {
        resolve(response.data)
      } else if (response.code === 500) {
        resolve([response])
      }
    }).catch((error) => {
      if (NProgress !== null) {
        if (json.loading === true) {
          NProgress.done()
        }
      }
      reject(error)
    })
  }))
}

const callSocket = (uri, json, onMessage, onclose) => {
  const ws = new WebSocket(urlWebApi.replace('https', 'wss').replace('http', 'ws') + uri.replace('/rs/', '/ws/'))
  ws.onopen = () => {
    if (NProgress !== null) {
      NProgress.start()
    }
    ws.send(JSON.stringify(json))
  }
  ws.onmessage = (evt) => {
    if (typeof onMessage === 'function') {
      const response = JSON.parse(evt.data)
      onMessage(response.data)
    }
  }
  ws.onclose = (evt) => {
    if (NProgress !== null) {
      NProgress.done()
    }
    if (typeof onclose === 'function') {
      onclose()
    }
  }
  ws.onerror = (evt) => {
    if (NProgress !== null) {
      NProgress.done()
    }
  }
}

const encryptRsa = (obj) => {
  const publicKey = forge.pki.publicKeyFromPem(publicKeyString)
  const encrypted = publicKey.encrypt(obj, 'RSA-OAEP', {
    md: forge.md.sha256.create(),
    mgf1: forge.mgf1.create(),
  })
  const base64 = forge.util.encode64(encrypted)
  return base64
}

const callUpload = (uri, fileToUpload) => {
  if (NProgress !== null) {
    NProgress.start()
  }
  return new Promise(((resolve, reject) => {
    const queryParams = {}
    fileToUpload.forEach((value, key) => {
      if (key === 'logico') {
        queryParams.logico = value
      }
      if (key === 'subpath') {
        queryParams.subpath = value
      }
    })
    // const callParams = {
    //   method: 'POST',
    //   url: urlWebApi + uri + '?logico=' + queryParams.logico + '&subpath='+ queryParams.subpath,
    //   headers: {
    //     'Content-Type': 'multipart/form-data'
    //   },
    //   data: fileToUpload,
    //   type: 'POST',
    //   processData: false,
    //   contentType: false,
    //   enctype: 'multipart/form-data'
    // }
    // axios(callParams).then(function (response) {
    axios.post(`${urlWebApi + uri}?logico=${queryParams.logico}&subpath=${queryParams.subpath}`, fileToUpload).then((response) => {
      if (NProgress !== null) {
        NProgress.done()
      }
      response = response.data
      if (response.code === 200) {
        resolve(response.data)
      } else if (response.code === 500) {
        resolve(undefined)
      }
    }).catch((error) => {
      if (NProgress !== null) {
        NProgress.done()
      }
      reject(error)
    })
  }))
}

const coreApi = {

  ejecutar: (spName, params, encriptado = 0, loading = true) => {
    // para pruebas
    // encriptado = 0
    // para pruebas
    const paramsToEncrypt = {
      nombre: spName,
      parametros: params,
    }
    const paramsEncryptedString = (encriptado === 1) ? encryptRsa(forge.util.encodeUtf8(JSON.stringify(paramsToEncrypt))) : ''

    const model = {
      nombre: (encriptado === 1) ? '' : spName,
      parametros: (encriptado === 1) ? paramsEncryptedString : params,
      encriptado: (encriptado === 1) ? 1 : undefined,
      loading: (loading === true),
    }
    let parameters = []
    if (encriptado === 0) {
      parameters = (Array.isArray(model.parametros)) ? model.parametros : toList(model.parametros)
      if (parameters.length > 0) { model.parametros = parameters }
    }

    return callWs(uris.execute, model)
  },

  catalog: (spName, params, encriptado = 0, loading = true) => {
    // para pruebas
    // encriptado = 0
    // para pruebas
    const paramsToEncrypt = {
      nombre: spName,
      parametros: params,
    }
    const paramsEncryptedString = (encriptado === 1) ? encryptRsa(forge.util.encodeUtf8(JSON.stringify(paramsToEncrypt))) : ''

    const model = {
      nombre: (encriptado === 1) ? '' : spName,
      parametros: (encriptado === 1) ? paramsEncryptedString : params,
      encriptado: (encriptado === 1) ? 1 : undefined,
      loading: (loading === true),
    }
    let parameters = []
    if (encriptado === 0) {
      parameters = (Array.isArray(model.parametros)) ? model.parametros : toList(model.parametros)
      if (parameters.length > 0) { model.parametros = parameters }
    }

    return callWs(uris.catalog, model)
  },

  upload: (fileUpload) => callUpload(uris.upload, fileUpload),

  notify: (data) => {
    callWs(uris.notify, data)
  },
  
  install (Vue, options = {}) {
    if (typeof options.axios !== 'undefined' && options.axios !== null) {
      axios = options.axios
    }
    if (typeof options.constants !== 'undefined' && options.constants !== null) {
      constants = options.constants
      urlWebApi = constants.urlWebApi
      publicKeyString = constants.publicKey
    }
    if (!Vue.prototype.$api) {
      Object.defineProperty(Vue.prototype, '$api', { value: coreApi })
    }
  },
}

export default coreApi
