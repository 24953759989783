import Vue from 'vue'
import coreApi from './services'

import envDesarrollo from '../../config/desarrollo.json'
import envProducción from '../../config/produccion.json'

// console.log('process.env', process.env.NODE_ENV)
let entorno = {}
if (process.env.NODE_ENV === 'development') {
  entorno = envDesarrollo.constants
} else {
  entorno = envProducción.constants
}

Vue.use(coreApi, { constants: entorno })

export default coreApi
