<template>
  <!-- Begin page -->
  <div class="error-bg">
    <div class="account-pages">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-8">
            <div class="card shadow-lg">
              <div class="card-block">
                <div class="text-center p-3">
                  <h1 class="error-page mt-4"><span>404!</span></h1>
                  <h4 class="mb-4 mt-5">Sorry, page not found</h4>
                  <a
                    class="btn btn-primary mb-4 waves-effect waves-light text-white"
                    @click="$router.push('/dashboard')"
                    ><i class="mdi mdi-home"></i> Back to Dashboard</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
