<template>
  <!-- Begin page -->
  <div class="accountbg">
    <div class="wrapper-page">
      <div class="card card-pages shadow-none" :class="temaVisual === 'dark' ? 'is-dark' : ''">
        <div class="card-body">
          <div class="text-center m-t-0 m-b-15">
            <a href="#" class="logo logo-admin">
              <b-img :src="temaVisual === 'dark' ? urlLogoDark : urlLogo" style="max-height: 100px;" alt="" height="100"/>
            </a>
          </div>
          <h5 class="font-18 text-center">{{ $t('login.updatePasswordTitle') }}</h5>
          <b-alert 
                :show="!!msgAlert && msgAlert.length > 0" 
                variant="danger" 
                dismissible 
                fade
                @dismissed="msgAlert = ''"
              >
                {{ msgAlert }}
              </b-alert>
          <form v-if="usuario" class="form-horizontal m-t-30" @submit.prevent="onSubmit">
            <div class="col-12">
              <h2>{{ usuario.nombre }}</h2>
              
            </div>

            <div class="form-group" :class="temaVisual === 'dark' ? 'is-dark' : ''">
              <div class="col-12">
                <label>{{ $t('login.password') }}</label>
                <div class="input-group">
                  <input
                    :name="$t('login.password')"
                    v-model="usuario.password"
                    class="form-control"
                    :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''"
                    :type="showPassword ? 'text' : 'password'"
                    placeholder=""
                    required
                  />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" @click="togglePassword"><i :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i></button>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group text-center m-t-20">
              <div class="col-12">
                <button
                  class="btn btn-primary btn-block btn-lg waves-effect waves-light"
                  type="submit"
                >
                  {{ $t('login.updatePassword') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- END wrapper -->
  </div>
</template>
<script>
import md5 from 'js-md5'
import { nanoid } from 'nanoid'
import { mapMutations, mapGetters } from 'vuex'
import Cookie from 'js-cookie'

let token = ''

export default {
  data () {
    return {
      msgAlert: '',
      usuario: null,   
      showPassword: false,
    }
  },
  computed: {
    ...mapGetters({
      urlLogo: 'getUrlLogo',
      urlLogoDark: 'getUrlLogoDark',
      urlImagenes: 'getUrlImagenes',
      temaVisual: 'getTemaVisual',
    }),
  },
  async mounted () {
    token = this.$route.params.token
    // consulta el token en la base de datos
    const user = await this.$api.ejecutar('verifica_usr', [token])
    // console.log('user ', user)
    if (!!user && Array.isArray(user) && !!user[0] && user[0].status !== 'ERROR') {
      this.usuario = { ...user[0] }
    } else {
      this.msgAlert = this.$t('login.invalidToken')
    }
  },
  methods: {
    ...mapMutations([
      'setUsuario',
    ]),
    async onSubmit () {
      const reglasPassword = [(v) => !!v || this.$t('login.passwordRequired')]
      let validPassword = ''
      reglasPassword.forEach((rule) => {
        validPassword = rule(this.usuario.password)
      })
      if (validPassword !== true) {
        this.msgAlert = validPassword
        return
      }
      const hash = md5.create()
      hash.update(this.usuario.password)

      try {
        const response = await this.$api
          .ejecutar('autoriza_usr', [token, this.usuario.correo, hash.hex()], 0)
        
        if (response.length > 0) {
          if (response[0].status !== null && response[0].status === 'ERROR') {
            this.msgAlert = response[0].message
            return
          } 
          this.$router.push('/login')
        }
      } catch (e) {
        this.msgAlert = this.$t('errors.networkError')
      }
    },
    togglePassword () {
      this.showPassword = !this.showPassword
    },
  },
}
</script>
