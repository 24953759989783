/* eslint-disable no-shadow */
const state = {
  empresaSeleccionada: null,
  empresaSeleccionadaIndex: null,
}
const getters = {
  empresaSeleccionada: (state) => state.empresaSeleccionada,
  empresaSeleccionadaIndex: (state) => state.empresaSeleccionadaIndex,
}

const mutations = {
  setEmpresaSeleccionada: (state, empresa) => {
    state.empresaSeleccionada = empresa
  },
  setEmpresaSeleccionadaIndex: (state, index) => {
    state.empresaSeleccionadaIndex = index
  },
}

const actions = {
}

const moduloEmpresas = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
export default moduloEmpresas
