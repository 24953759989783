<template>
    <!-- Start content -->
  <div class="content">
    <div class="container-fluid">
      <div class="page-title-box">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <!-- <h4 class="page-title">{{ $t('devices.actionTitle') }}</h4> -->
            <h4 class="page-title">
              <i :class="`fa ${accionSeleccionada.imagen} mr-2`" ></i>
              <span class="mr-2 ">{{ $t(`devices.actionsLabels.${accionSeleccionada.id_accion}`) }}</span>
            </h4>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end page-title -->
    <h5 :class="temaVisual === 'dark' ? 'text-muted' : ''">{{ dispositivo.alias ? dispositivo.alias : dispositivo.id_dispositivo }}</h5>
    <!-- <b-card no-body class="overflow-hidden p-2 col-xs-12 col-sm-12 col-md-6 col-lg-4" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow rounded' : 'shadow rounded'" style="border-radius: 15px !important;">
      <b-row>
        <b-col md="12">
          <b-row>
            <b-col>
              <img :src="temaVisual === 'dark' ? urlLogoDark : urlLogo" alt="BTap" class="rounded-0 p-1" width="100">
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h5>{{ dispositivo.alias ? dispositivo.alias : dispositivo.id_dispositivo }}</h5> -->
              <!-- <h5>{{ dispositivo.id_dispositivo }}</h5> -->
              <!-- <b-form-input
                class="mb-3 ml-1"
                :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : 'is-light bg-transparent'"
                name="input-alias"
                id="input-alias"
                type="text"
                size="sm"
                :placeholder="$t('devices.alias')"
                @change="updateAlias(dispositivo)"
                @keyup.enter="updateAlias(dispositivo)"
                v-model="dispositivo.alias"
              >
              </b-form-input> -->
              <!-- <h6>{{ dispositivo.id_accion_principal ? $t(`devices.actionsLabels.${dispositivo.id_accion_principal}`) : ''}}</h6> -->
            <!-- </b-col>
          </b-row>
        </b-col>
      </b-row> -->
      <!-- <b-row>
        <b-col sm="12">
        <i class="fa fa-clipboard fa-2x mr-3 float-right text-btap-primary" v-b-tooltip.hover :title="$t('devices.copyURL')" @click="copiarQR(dispositivo)"></i>
        </b-col>
      </b-row> -->
    <!-- </b-card> -->

    <!-- <b-dropdown 
      v-if="$mq !== 'mobile'"
      id="dropdown-acciones"
      block
      size="lg" 
      class="h6 col-xs-12 col-sm-12 col-md-6 col-lg-4" 
      variant="primary"
    >
      <template slot="button-content">
        <i :class="`fa ${accionSeleccionada.imagen} mr-2`" ></i>
        <span class="mr-2 ">{{ $t(`devices.actionsLabels.${accionSeleccionada.id_accion}`) }}</span>
      </template>
      <b-dropdown-item 
        v-for="ac in itemsAcciones" 
        :key="ac.id_accion"
        @click="seleccionaAccion(ac)"
        >
        <i :class="`fa ${ac.imagen} mr-2`" ></i>
          <span class="mr-2" >{{ $t(`devices.actionsLabels.${ac.id_accion}`) }}</span>
        
      </b-dropdown-item>
      
    </b-dropdown>

    <b-button 
      v-if="$mq === 'mobile'"
      class="h6 col-xs-12 col-sm-12 col-md-6 col-lg-4" 
      variant="primary"
      :class="temaVisual === 'dark' ? 'is-dark' : ''"
      v-b-toggle.sidebar-acciones
      >
      <i :class="`fa ${accionSeleccionada.imagen} mr-2`" ></i>
        <span class="mr-2 ">{{ $t(`devices.actionsLabels.${accionSeleccionada.id_accion}`) }}</span>
    </b-button> -->
    <!-- <b-sidebar 
      id="sidebar-acciones" 
      :title="$t('devices.actionTitle')" 
      shadow 
      :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none bg-btap-primary' : ''" 
      :bg-variant="temaVisual === 'dark' ? 'dark' : 'light'"
      :text-variant="temaVisual === 'dark' ? 'light' : 'dark'">
      <template #default="{ hide }">
      <nav class="mb-3" >
        <b-nav vertical pills>
          <b-nav-item 
            v-for="ac in itemsAcciones" 
            :key="ac.id_accion"
            @click="{ seleccionaAccion(ac);  hide; }"
            :active="accion === ac.id_accion"
          >
            <i :class="`fa ${ac.imagen} mr-2`" ></i>
            <span class="mr-2" >{{ $t(`devices.actionsLabels.${ac.id_accion}`) }}</span>
          </b-nav-item>
        </b-nav>
      </nav>
      </template>
    </b-sidebar> -->

    <b-alert
      :show="!!msgAlert && msgAlert.length > 0"
      variant="danger"
      dismissible
      fade
    >
      {{ msgAlert }}
    </b-alert>

    <b-form ref="actionsForm" name="actionsForm" id="actionsForm" class="fluid m-0 p-0"
      :class="temaVisual === 'dark' ? 'text-muted' : ''"
      @submit.stop.prevent>
    
      <!-- Formulario Acciones -->
      
        <b-row>
        <b-col 
          v-for="(label, index) in plantillaDetalle.filter((pl) => pl.validador === 'label')" 
          :key="index"
          sm="12"
          md="6"
        >
          <b-card no-body class="mb-3" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : 'shadow rounded'">
            <h6 v-if="$t('devices.action.' + accion + '.' + label.identificador)"  class="pt-2 pl-2">{{ $t('devices.action.' + accion + '.' + label.identificador) }}</h6>
            <div v-else class="mb-2"></div>
            <b-form-group 
              v-for="(campo, idx) in plantillaDetalle.filter((pl) => pl.validador !== 'label' && pl.grupo === label.grupo)"
              :key="idx"
              :label="$t('devices.action.' + accion + '.' + campo.identificador)"
              :label-for="'input-' + campo.id_plantilla_detalle"
              class="form-control-label ml-3 mr-2"
            >
              <template v-if="plantilla.es_ingreso_datos === 'S'" #label>
                <label>{{ campo.etiqueta ? campo.etiqueta : $t(`devices.action.${accion}.variableLabel`) }}</label>
                <b-form-checkbox
                  v-if="plantilla.es_ingreso_datos === 'S'"
                  v-model="campo.es_obligatorio"
                  :disabled="campo.deshabilitado === 'S'"
                  value="S"
                  unchecked-value="N"
                  switch
                  size="lg"
                  class="float-right"
                  @change="cambiarEtiqueta(campo)"
                >
                  <!-- <span class="fw-lighter" style="font-weight: lighter !important; font-size: 0.8em !important;">{{ $t('devices.action.' + accion + '.required') }}</span> -->
                </b-form-checkbox>
              </template>
              <!-- <template v-if="campo.es_etiqueta_variable === 'S'" slot="label">
                <b-input-group class="mt-3 form-control-label">
                  <b-form-input
                    :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''"
                    :name="'inputL-' + campo.id_plantilla_detalle"
                    :id="'inputL-' + campo.id_plantilla_detalle"
                    type="text"
                    :placeholder="$t(`devices.action.${accion}.variableLabel`)"
                    v-model="campo.etiqueta"
                  />
                </b-input-group>
              </template> -->
              <b-input-group size="sm" class="mb-0 mr-0">
                <template #prepend v-if="campo.validador === 'url'">

                  <b-form-select 
                    v-if="campo.validador === 'url'"
                    size="sm"
                    class="mb-3 ml-1 mr-0"
                    :class="temaVisual === 'dark' ? 'bg-transparent' : ''"
                    v-model="campo.prefix"
                    :options="itemsHttp"
                    @input="togglePrefixURL(campo, campo.prefix)"
                  >
                  </b-form-select>
                </template>
                <b-form-input
                  v-if="specificFields.includes(campo.validador) === false && plantilla.es_ingreso_datos === 'N'"
                  class="mb-3 ml-1"
                  :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : 'is-light bg-transparent'"
                  :name="'input-' + campo.id_plantilla_detalle"
                  :id="'input-' + campo.id_plantilla_detalle"
                  :type="campo.validador"
                  :rules="{ required: campo.es_oblitatorio === 'S'}"
                  :required="campo.es_oblitatorio === 'S'"
                  :readonly="plantilla.es_ingreso_datos === 'S'"
                  :disabled="plantilla.es_ingreso_datos === 'S'"
                  placeholder=""
                  v-model="campo.valor"
                >
                </b-form-input>
                <b-form-input
                  v-if="campo.validador === 'url'"
                  class="mb-3 ml-1"
                  :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : 'is-light bg-transparent'"
                  :name="'input-' + campo.id_plantilla_detalle"
                  :id="'input-' + campo.id_plantilla_detalle"
                  :type="campo.validador"
                  :rules="{ required: campo.es_oblitatorio === 'S'}"
                  :required="campo.es_oblitatorio === 'S'"
                  :readonly="plantilla.es_ingreso_datos === 'S'"
                  :disabled="plantilla.es_ingreso_datos === 'S'"
                  placeholder=""
                  v-model="campo.valor"
                  @blur="togglePrefixURL(campo, campo.prefix)"
                >
                </b-form-input>
                <b-form-textarea
                  v-if="campo.validador === 'textarea'  && plantilla.es_ingreso_datos === 'N'"
                  class="mb-3 ml-1"
                  :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : 'is-light bg-transparent'"
                  :name="'input-' + campo.id_plantilla_detalle"
                  :id="'input-' + campo.id_plantilla_detalle"
                  :type="campo.validador"
                  :rules="{ required: campo.es_oblitatorio === 'S'}"
                  :required="campo.es_oblitatorio === 'S'"
                  :readonly="plantilla.es_ingreso_datos === 'S'"
                  :disabled="plantilla.es_ingreso_datos === 'S'"
                  placeholder=""
                  v-model="campo.valor"
                  rows="4"
                >
                </b-form-textarea>
                <tel-input
                  v-if="campo.validador === 'tel'"
                  class="form-control custom-control block mb-3 ml-1"
                  :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : 'is-light bg-transparent'"
                  :style="temaVisual === 'dark' ? 'background-color: transparent; ' : ''"
                  :name="'input-' + campo.id_plantilla_detalle"
                  :id="'input-' + campo.id_plantilla_detalle"
                  :type="campo.validador"
                  :rules="{ required: campo.es_oblitatorio === 'S'}"
                  :required="campo.es_oblitatorio === 'S'"
                  :readonly="plantilla.es_ingreso_datos === 'S'"
                  :disabled="plantilla.es_ingreso_datos === 'S'"
                  placeholder=""
                  mode="international"
                  :inputData.sync="campo.valor"
                >
                </tel-input>
                <!-- <b-calendar 
                  v-if="campo.validador === 'calendar'"
                  v-model="campo.valor"
                  :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : 'is-light bg-transparent'"
                >
                </b-calendar>
                <b-time 
                  v-if="campo.validador === 'time'"
                  v-model="campo.valor"
                  :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : 'is-light bg-transparent'"
                >
                </b-time> -->
                <div 
                  v-if="campo.validador === 'file' && campo.es_imagen === 'S'"
                  class="embed-responsive embed-responsive-16by9 border-1 mb-3 ml-1" 
                  :class="temaVisual === 'dark' ? 'is-dark text-muted rounded' : 'rounded'" 
                  style="max-height: 150px;" 
                  :style="$mq === 'mobile' ? 'max-width: 100%' : 'width: 165px'">
                  <div class="card embed-responsive-item"  >
                    <!-- <label v-if="campo && (campo.files || campo.valor)" 
                      class="fab-btn bg-danger" 
                      style="background-color: rgba(0,0,0,0.1) !important"
                      @click.prevent="campo.files = null; campo.valor = ''">
                      <span><i class="fa fa-times"></i></span>
                    </label> -->
                    <img
                      v-if="campo && (campo.files || campo.valor)" 
                      class="card-img-top" 
                      :src="!!campo.files && campo.files !== null ? campo.files.url : campo && campo.valor ? campo.valor + '?' + (new Date()).getTime() : 'images/noimage.png'"
                      onerror="this.src='images/noimage.png'"
                      style="object-fit: cover;"
                    >
                    <div v-if="!campo.files && !campo.valor" 
                      class="card-img-overlay d-flex flex-column justify-content-center text-center" 
                      :style="temaVisual === 'dark' ? 'background-color: rgba(46,48,74,0.8) !important' : 'background-color: rgba(244,244,244, 0.8)'" 
                      @click.prevent="clickFoto('input-' + campo.id_plantilla_detalle)">
                      <label class="label card-title mt-1" >{{ $t('global.clickToUpload') }}</label>
                    </div>
                  </div>
                  
                </div>
                
                <b-form-file 
                  v-if="campo.validador === 'file'"
                  :name="'input-' + campo.id_plantilla_detalle"
                  :id="'input-' + campo.id_plantilla_detalle"
                  ref="foto" 
                  v-model="campo.files" 
                  class="btn btn-primary d-none" 
                  plain
                  @input="filesChange(campo)"  
                >
                </b-form-file>
                <!-- <verte 
                  v-if="campo.validador === 'color'"
                  picker="square" 
                  v-model="campo.valor"
                  value="#002349"
                  model="hex"
                >
                
                </verte> -->
                <!-- {{campo.validador === 'color' ? campo.valor : ''}} -->
                <!-- <b-input-group-append is-text> -->
                  
                <!-- </b-input-group-append> -->
              </b-input-group>
              <b-button v-if="campo && (campo.files || campo.valor) && campo.validador === 'file' && campo.es_imagen === 'S'" 
                  variant="transparent"
                  class="mb-3 ml-1 border-0 text-muted"
                  :block="$mq === 'mobile'"
                  @click.prevent="campo.files = null; campo.valor = ''">
                  <span><i class="fa fa-trash-alt mr-1"></i>{{ $t('global.clearImage') }}</span>
                </b-button>
            </b-form-group>
          </b-card>
        </b-col>
        </b-row>
    </b-form>

    <b-row v-if="accion && plantilla.es_ingreso_datos === 'S'">
      <b-col xs="12" md="6">
        <fieldset 
          v-if="accion && plantilla.es_ingreso_datos === 'S'"
          class="callout callout-primary mb-3"
          :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''"
        >
          {{ $t('devices.action.' + accion + '.inputForm') }}
          <b-form-checkbox
            v-if="plantilla.es_ingreso_datos === 'S'"
            size="sm"
            v-model="almacenar_datos"
            value="S"
            unchecked-value="N"
          >
            {{ $t('devices.action.' + accion + '.saveOnServer') }}
          </b-form-checkbox>
          <b-form-checkbox
            v-if="plantilla.es_ingreso_datos === 'S'"
            size="sm"
            v-model="enviar_correo"
            value="S"
            unchecked-value="N"
          >
            {{ $t('devices.action.' + accion + '.sentByEmail') }}
          </b-form-checkbox>
        </fieldset>
      </b-col>
    </b-row>

    <b-row v-if="accion && accion === 'PROFILE'">
      <b-col>
        <fieldset 
          v-if="accion && accion === 'PROFILE'"
          class="callout callout-primary mb-3"
          :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''"
        >
          {{ $t('devices.action.' + accion + '.inputForm') }}
        </fieldset>
      </b-col>
    </b-row>
    
    <b-modal 
      v-model="showModalEtiquetaVariable" 
      :hide-footer="true" :hide-header="true" 
      :body-bg-variant="temaVisual === 'dark' ? 'page' : ''"
      :class="temaVisual === 'dark' ? 'text-muted' : ''" 
      centered>
      <h6 class="pt-2" :class="temaVisual === 'dark' ? 'text-muted' : ''">{{ $t(`devices.action.${accion}.variableLabel`) }}</h6>
      <b-form-input
        class="mb-3 mr-2"
        :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : 'is-light bg-transparent'"
        name="input-alias"
        id="input-alias"
        ref="inputAlias"
        type="text"
        size="sm"
        placeholder=""
        v-model="field.etiqueta"
        :autofocus="true"
      >
      </b-form-input>
      <b-button 
          :block="$mq === 'mobile'"
          class="mr-2" 
          @click="showModalEtiquetaVariable = false; field.es_obligatorio = field.etiqueta ? 'S' : 'N'"
          variant="primary">
          <b-icon icon="upload" class="font-weight-bolder mr-2"></b-icon>&nbsp;{{ $t('global.update') }}
      </b-button>
      <b-button 
          :block="$mq === 'mobile'"
          class="mr-2"
          @click="showModalEtiquetaVariable = false; field.es_obligatorio = field.etiqueta ? 'S' : 'N'" 
          type="warning" 
          :icon="true"
          >
          <b-icon icon="backspace" class="font-weight-bolder mr-2"></b-icon>&nbsp;{{ $t('global.cancel') }}
      </b-button>
    </b-modal>

    <div class="mt-1">
      
        <!-- <center> -->
        <b-button
          v-if="accionSeleccionada.solo_ejecutar === 'N' && accionSeleccionada.es_captura_datos === 'N'"
          v-show="dispositivoSeleccionado !== null"
          :loading="loading"
          class="mr-2 mb-1"
          :class="!valid || valid === false || loading ? 'text-muted' : ''"
          @click="save(true)"
          variant="primary"
          :block="$mq === 'mobile'"
          ><b-icon icon="eye" class="mr-2 font-weight-bolder"></b-icon>&nbsp;{{
            $t("global.updateNPreview")
          }}</b-button
        >  
        <b-button
          v-show="dispositivoSeleccionado !== null"
          :loading="loading"
          class="mr-2 mb-1"
          :class="!valid || valid === false || loading ? 'text-muted' : ''"
          @click="save(false)"
          variant="primary"
          :block="$mq === 'mobile'"
          ><b-icon icon="upload" class="mr-2 font-weight-bolder"></b-icon>&nbsp;{{
            $t("global.update")
          }}</b-button
        >
        <b-button @click="back" variant="secondary" :icon="true" class="mr-2 mb-1" :block="$mq === 'mobile'"
          ><b-icon icon="backspace" class="mr-2 mb-1 font-weight-bolder"></b-icon
          >&nbsp;{{ $t("global.cancel") }}</b-button
        >
        <!-- </center> -->
        <b-alert
            :show="!!msgAlert && msgAlert.length > 0"
            variant="danger"
            dismissible
            fade
            >{{ msgAlert }}</b-alert
          >

    </div>
    
  </div>
    <!-- container-fluid -->
  </div>
  <!-- content -->
</template>
<script>
// import Verte from 'verte'
// import 'verte/dist/verte.css'
import { mapState, mapGetters, mapMutations } from "vuex"
import { nanoid } from "nanoid"
import QRCode from "qrcode"
import schemify from 'url-schemify'
import TelInput from '@/components/TelInput.vue'

let vueRef = null

const dispositivoInicial = {
  id_dispositivo: null,
  id_empresa: null,
  id_tipo_dispositivo: null,
  es_masivo: "N",
  cantidad_dispositivos: 1,
  estado: "A",
}

const copyToClipboard = (str) => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

const colorPredefinido = '#002349'

export default {
  components: {
    TelInput,
  },
  data () {
    return {
      valid: true,
      loading: false,
      tabIndex: 0,
      color: colorPredefinido,
      dispositivo: {},
      show: true,
      msgAlert: "",
      srcQR: "https://via.placeholder.com/150",
      itemsTiposDispositivo: [],
      itemsAcciones: [],
      accion: '',
      accionObj: {},
      plantilla: {},
      plantillaDetalle: [],
      almacenar_datos: 'N',
      enviar_correo: 'N',
      itemsMasivo: [
        { text: this.$t("devices.isMassiveLabel.N"), value: "N" },
        { text: this.$t("devices.isMassiveLabel.S"), value: "S" },
      ],
      itemsEstado: [
        { text: this.$t("devices.statusLabel.A"), value: "A" },
        { text: this.$t("devices.statusLabel.I"), value: "I" },
      ],
      itemsHttp: [
        { text: 'http', value: "http" },
        { text: 'https', value: "https" },
      ],
      specificFields: [
        'textarea',
        'file',
        // 'color',
        // 'calendar',
        // 'time',
        'tel',
        'url',
      ],
      field: {},
      showModalEtiquetaVariable: false,
    }
  },
  computed: {
    ...mapGetters({
      temaVisual: "getTemaVisual",
      usuarioAuth: "getUsuario",
      parametrosGenerales: "getParametrosGenerales",
      urlImagenes: "getUrlImagenes",
      urlLogo: "getUrlLogo",
      urlLogoDark: "getUrlLogoDark",
      urlAcciones: "getUrlAcciones",
    }),
    ...mapGetters("empresas", [
      "empresaSeleccionada",
      "empresaSeleccionadaIndex",
    ]),
    ...mapGetters("dispositivos", [
      "dispositivoSeleccionado",
      "dispositivoSeleccionadoIndex",
      "accionSeleccionada",
    ]),
    // accionSeleccionada () {
    //   if (!!this.accion && this.accion !== null) {
    //     if (!!this.itemsAcciones && Array.isArray(this.itemsAcciones) && this.itemsAcciones.length > 0) {
    //       const seleccionada = this.itemsAcciones.filter((ac) => ac.id_accion === this.accion)
    //       return seleccionada[0]
    //     }
    //   }
    //   if (!!this.itemsAcciones && Array.isArray(this.itemsAcciones) && this.itemsAcciones.length > 0) {
    //     return this.itemsAcciones[0]
    //   }
    //   return {}
    // },
  },

  async mounted () {
    vueRef = this
    this.loading = true
    this.itemsTiposDispositivo = await this.$api.ejecutar("CATALOGOS", [
      "TIPOS_DISPOSITIVO",
    ])
    this.itemsAcciones = await this.$api.ejecutar("CATALOGOS", [
      "ACCIONES",
    ])
    if (
      !!this.dispositivoSeleccionado
      && this.dispositivoSeleccionado !== null
    ) {
      this.dispositivo = {
        ...JSON.parse(JSON.stringify(this.dispositivoSeleccionado)),
      }
      // const accionesDispositivo = await this.$api.ejecutar("ACCIONES_DISPOSITIVO_SEL", [this.usuarioAuth.token, this.dispositivo.id_dispositivo])
      // if (Array.isArray(accionesDispositivo) && accionesDispositivo.length > 0) {
      //   this.accion = accionesDispositivo[0].id_accion
      // } else { // es un dispositivo nuevo
      //   this.accion = this.itemsAcciones[0].id_accion
      // }
      this.accion = this.accionSeleccionada.id_accion
      this.mostrarFormularioAcciones()
    } else {
      this.dispositivo = { ...dispositivoInicial }
      this.dispositivo.id_dispositivo = nanoid()
      this.dispositivo.id_empresa = this.empresaSeleccionada.id_empresa
      if (this.itemsTiposDispositivo.length > 0) {
        this.dispositivo.id_tipo_dispositivo = this.itemsTiposDispositivo[0].id_tipo_dispositivo
      }
    }
    this.srcQR = await QRCode.toDataURL(
      `${this.urlAcciones}/${this.dispositivo.id_dispositivo}`,
      { width: 512 },
    )
    this.loading = false
  },

  methods: {
    ...mapMutations("dispositivos", ["setDispositivoSeleccionado"]),
    onSubmit (evt) {
      evt.preventDefault()
    },
    async onReset (evt) {
      // evt.preventDefault()
      // Reset our form values
      this.loading = true
      this.dispositivo = { ...dispositivoInicial }
      this.dispositivo.id_dispositivo = nanoid()
      this.dispositivo.id_empresa = this.empresaSeleccionada.id_empresa
      if (this.itemsTiposDispositivo.length > 0) {
        this.dispositivo.id_tipo_dispositivo = this.itemsTiposDispositivo[0].id_tipo_dispositivo
      }
      this.srcQR = await QRCode.toDataURL(
        `${this.urlAcciones}/${this.dispositivo.id_dispositivo}`,
        { width: 512 },
      )
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.loading = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    async remove () {
      try {
        this.loading = true
        const params = [
          this.usuarioAuth.token,
          this.dispositivo.id_dispositivo,
        ]
        const response = await this.$api.ejecutar("dispositivo_del", params)
        this.loading = false
        if (response.length > 0) {
          if (response[0].status !== null && response[0].status === "ERROR") {
            this.msgAlert = response[0].message
            return
          }
          this.back()
        }
      } catch (e) {
        // console.log("Error remove ", e)
        this.loading = false
        this.msgAlert = this.$t("errors.networkError")
      }
    },
    async save (saveNew = false) {
      // revisa campos que son obligatorios
      // console.log('graba acciones')
      // debugger
      this.valid = true
      this.plantillaDetalle.filter((pl) => pl.validador !== 'label').forEach((campo) => {
        if (campo.es_obligatorio === 'S' && this.plantilla.es_ingreso_datos === 'N') {
          if (!campo.valor || campo.valor === null || campo.valor.length <= 0) {
            this.valid = false
            this.msgAlert = this.$t('global.isRequired', { label: this.$t(`devices.action.${this.accion}.${campo.identificador}`) })
          }
        }
      })
      
      if (this.plantilla.es_ingreso_datos === 'S') {
        // eslint-disable-next-line prefer-const
        let etiquetas = []
        // console.log('etiquetas', etiquetas)
        this.plantillaDetalle.filter((pl) => pl.validador !== 'label').forEach((campo) => {
          if (campo.etiqueta && campo.etiqueta !== null && campo.etiqueta.length > 0) {
            if (etiquetas.includes(campo.etiqueta)) {
              this.valid = false
              this.msgAlert = this.$t('global.duplicateCustomLabels')
            } else {
              etiquetas.push(campo.etiqueta)
              // console.log('etiquetas', etiquetas)
            }
          }
        })
      } 
      if (this.valid === true) {
        try {
          // verifica si la accion es captura de datos,
          // se debe seleccionar almacenar datos o enviar correo
          if (this.plantilla.es_ingreso_datos === 'S') {
            if (this.almacenar_datos === 'N' && this.enviar_correo === 'N') {
              this.msgAlert = this.$t('devices.leadsAlertSave')
              return
            }
          }

          // actualiza la accion para el dispositivo
          this.loading = true
          const params = [
            this.usuarioAuth.token,
            this.dispositivo.id_dispositivo,
            this.accion,
            this.almacenar_datos,
            this.enviar_correo,
          ]
          const response = await this.$api.ejecutar("acciones_dispositivo_iu", params)
          // console.log("response save ", response)
          this.loading = false
          if (Array.isArray(response)) {
            if (
              !!response[0]
              && response[0].status !== null
              && response[0].status === "ERROR"
            ) {
              // console.log("response existe error ", response[0])
              this.msgAlert = response[0].message
              return
            }
            // console.log("no existe Error")
            const datos = {
              id: this.dispositivo.id_dispositivo, 
            }
            if (this.plantilla.es_ingreso_datos === 'N') {
              datos[this.accion] = {}
              this.plantillaDetalle.forEach((plantilla) => {
                datos[this.accion][plantilla.identificador] = plantilla.valor
                if (plantilla.validador === 'url') {
                  datos[this.accion][`_url_${plantilla.identificador}`] = plantilla.realUrl
                }
              })
              // si es accion profile revisa si tiene VCARD
              if (this.accion === 'PROFILE') {
                const resultVCARD = await this.$api.catalog("obtenerAccionesDispositivo", [this.usuarioAuth.token, { id: this.dispositivo.id_dispositivo }])
                // eslint-disable-next-line dot-notation
                if (!!resultVCARD && Array.isArray(resultVCARD) && !!resultVCARD[0] && !!resultVCARD[0]['VCARD']) {
                  datos[this.accion].tieneVcard = 'S'
                } else {
                  datos[this.accion].tieneVcard = ''
                }
              }
            } else {
              datos[this.accion] = {}
              datos[this.accion].almacenarDatos = this.almacenar_datos
              datos[this.accion].enviarCorreo = this.enviar_correo
              datos[this.accion].campos = []
              this.plantillaDetalle.forEach((plantilla) => {
                if (plantilla.validador !== 'label' && !(plantilla.es_etiqueta_variable === 'S' && (plantilla.etiqueta === '' || plantilla.etiqueta === null))) { // no se trasladan las etiquetas
                  datos[this.accion].campos.push(plantilla)
                }
              })
            }
            // console.log("datos ", datos)
            const result = await this.$api.catalog("agregarAccionDispositivo", [this.usuarioAuth.token, datos])
            // console.log("testSaveNew ", saveNew)
            if (saveNew === true) {
              this.dispositivo.id_accion_principal = this.accionSeleccionada.id_accion
              // this.$nextTick()
              //   .then(() => {
              //     this.refreshPreview()
              //     this.tabIndex = 1
              //   })
              this.$router.push('/devices/preview')
            } else {
              this.back()
            }
          }
        } catch (e) {
          // console.log("Error remove ", e)
          this.loading = false
          this.msgAlert = this.$t("errors.networkError")
        }
      }
    },
    refreshPreview () {
      const iframe = document.getElementById('previewWindow')
      if (iframe) {
        if (iframe.contentDocument) {
          iframe.contentDocument.location.reload(true)
        }
      }
    },
    descargarQR () {
      // nothing
      const link = document.createElement("a")
      link.href = this.srcQR
      link.download = `${this.dispositivo.id_dispositivo}.png`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    copiarQR () {
      copyToClipboard(`${this.urlAcciones}/${this.dispositivo.id_dispositivo}`)
    },
    async mostrarFormularioAcciones () {
      // debugger
      const accion = this.itemsAcciones.filter((elem) => elem.id_accion === this.accion)
      this.accionObj = { ...accion[0] }
      const plantilla = await this.$api.ejecutar('plantilla_x_id_sel', [accion[0].id_plantilla])
      this.plantilla = { ...plantilla[0] }
      this.plantillaDetalle = await this.$api.ejecutar('plantilla_detalle_x_id_sel', [accion[0].id_plantilla])
      // obtiene los datos de la accion
      const result = await this.$api.catalog("obtenerAccionesDispositivo", [this.usuarioAuth.token, { id: this.dispositivo.id_dispositivo }])
      // console.log('datos accion ', result)
      if (!!result && Array.isArray(result) && !!result[0] && !!result[0][this.accion]) {
        const detalles = [] 
        this.plantillaDetalle.forEach((detalle) => {
          const detail = JSON.parse(JSON.stringify(detalle))
          if (detail.validador === 'color') {
            detail.valor = colorPredefinido
          }
          if (detail.validador === 'url') {
            detail.prefix = 'http'
            detail.realUlr = ''
          }
          detalles.push(detail)
        })
        // console.log('plantilla detalle ', this.plantillaDetalle)
        // console.log('plantilla detalles ', detalles)
        if (result[0][this.accion].almacenarDatos) {
          this.almacenar_datos = result[0][this.accion].almacenarDatos
        }
        if (result[0][this.accion].enviarCorreo) {
          this.enviar_correo = result[0][this.accion].enviarCorreo
        }
        this.plantillaDetalle = []
        Object.keys(result[0][this.accion]).forEach((identificador) => {
          detalles.forEach((detalle) => {
            if (detalle.identificador === identificador) {
              detalle.valor = result[0][this.accion][identificador]
              
              if (detalle.validador === 'color' && (!detalle.valor || detalle.valor === null)) {
                // console.log("es color ", detalle.valor)
                detalle.valor = colorPredefinido
                // console.log("es color ", detalle.valor)
              }
              if (detalle.validador === 'url' && (!detalle.prefix || detalle.prefix === null)) {
                detalle.prefix = 'http'
                detalle.realUlr = ''
              }
            } 
          })
        })
        if (Object.keys(result[0][this.accion]).includes('campos')) {
          detalles.forEach((detalle) => {
            if (detalle.es_etiqueta_variable === 'S') {
              // busca el identificador en los campos
              if (result[0][this.accion].campos) {
                result[0][this.accion].campos.forEach((cmp) => {
                  // console.log('cmp ', cmp.identificador, cmp.etiqueta)
                  if (cmp.identificador === detalle.identificador) {
                    detalle.etiqueta = cmp.etiqueta
                  }
                })
              }
            }
            // actualiza los que son obligatorios
            if (result[0][this.accion].campos) {
              result[0][this.accion].campos.forEach((cmp) => {
                // console.log('cmp ', cmp.identificador, cmp.etiqueta)
                if (cmp.identificador === detalle.identificador) {
                  detalle.es_obligatorio = cmp.es_obligatorio
                }
              })
            }
          })
        }
        detalles.forEach((detalle) => {
          this.plantillaDetalle.push(detalle)
        })
        // console.log('plantilla detalle ', this.plantillaDetalle)
      }
    },
    back () {
      this.setDispositivoSeleccionado(null)
      this.$router.push("/devices")
    },
    seleccionaAccion (item) {
      this.accion = item.id_accion
      // console.log("sel dispositivo ", this.dispositivo)
      // console.log("sel accion", this.accionSeleccionada)
      this.mostrarFormularioAcciones()
    },
    filesChange (campo) {
      // console.log('files ', typeof this.files, this.files)
      const fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi
      if (!!campo.files && campo.files !== null) {
        if (typeof campo.files === 'object') {
          if (campo.files.size / 1024 / 1024 > 2) {
            // console.log('Image Size: ', this.files.size/1024/1024)
            this.msgAlert = this.$t('global.maxImageSize')
            campo.files = null
            return
          }
          campo.files.url = URL.createObjectURL(campo.files)
          const extension = campo.files.name.match(fileExtensionPattern)[0]
          campo.files.extension = extension
          campo.valor = `${this.urlImagenes}/${this.dispositivo.id_dispositivo}/${campo.files.name}`
          this.uploadImage(campo)
        }
        if (Array.isArray(campo.files)) {
          campo.files.forEach((element) => {
            element.url = URL.createObjectURL(element)
            const extension = element.name.match(fileExtensionPattern)[0]
            element.extension = extension
            campo.valor = `${this.urlImagenes}/${this.dispositivo.id_dispositivo}/${element.name}`
            this.uploadImage(campo)
          })
        }
      }
    },
    async uploadImage (campo) {
      // console.log('realiza upload')
      try {
        this.loading = true
        if (!!campo.files && !!campo.files !== null) {
          // realiza el upload de la imagen
          const formEnviarDocumento = new FormData()
          // debugger
          formEnviarDocumento.append('file', campo.files)
          formEnviarDocumento.append('logico', campo.files.name)
          formEnviarDocumento.append("subpath", `${this.dispositivo.id_dispositivo}/`) 
          // console.log('cargando imagen')
          const responseUpload = await this.$api.upload(formEnviarDocumento)
          // console.log('responseUpload ', responseUpload)
          this.loading = false
          if (Array.isArray(responseUpload)) {
            // debugger
            if (!!responseUpload[0] && responseUpload[0].status === 'ERROR') {
              // console.log(`Hubo un error al guardar el ${this.files.name} en el servidor. Verifique el documento que desea guardar`)
              return { status: 'ERROR', message: this.$t('errorUploadImage', { label: this.files.name }) }
            }
          }
        }
        return { status: 'OK', message: '' }
      } catch (e) {
        this.loading = false
        this.msgAlert = this.$t('errors.networkError')
        return { status: 'ERROR', message: this.$t('errors.networkError') }
      }
    },
    togglePrefixURL (campo, prefix) {
      // campo.prefix = prefix
      if (campo.valor && campo.valor !== null && campo.valor.length > 0) {
        campo.realUrl = schemify(campo.valor.replace('https://', '').replace('http://', ''), { scheme: campo.prefix })
      } else {
        campo.realUrl = ''
      }
    },
    async updateAlias (item) {
      // console.log('updating alias ')
      this.loading = true
      try {
        const params = [
          this.usuarioAuth.token,
          item.id_dispositivo,
          item.alias,
        ]
        const response = await this.$api.ejecutar('actualiza_alias', params)
        this.loading = false
        if (response.length > 0) {
          if (response[0].status !== null && response[0].status === 'ERROR') {
            this.msgAlert = response[0].message
            return
          } 
        }
      } catch (e) {
        this.loading = false
        this.msgAlert = this.$t('errors.networkError')
      }
    },
    showContent (ac) {
      // eslint-disable-next-line prefer-template
      return `<i class="fa ${ac.imagen} mr-2" ></i><span class='mr-2' >${this.$t("devices.actionsLabels." + ac.id_accion)}</span>`
    },
    clickFoto (id) {
      const foto = document.getElementById(id)
      foto.click()
    },
    cambiarEtiqueta (campo) {
      if (campo.es_etiqueta_variable === 'S' && campo.es_obligatorio === 'S') {
        this.field = campo
        this.showModalEtiquetaVariable = true
      }
    },
  },
}
</script>
<style>
fieldset {
  border-radius: 4px;
}

legend {
  font-size: 17px;
  font-weight: bold;
  padding: 3px 5px 7px 7px;
  width: auto;
}

.vue-tel-input .dropdown ul {
  z-index: 3 !important;
}
.vti__dropdown-list {
  z-index: 3 !important;
}
.vue-tel-input.is-dark > .vti__input {
  background-color: transparent;
  color: #7d7e87 !important;
}

.fab-btn {
  position: absolute;
  /* bottom: 12px; */
  right: -1px;
  border-radius: 10%;
  width: 50px;
  height: 50px;
  transition: box-shadow 0.4s ease;
  /* background: #4610f5; */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  color: #f4f4f4;
  font-size: 1.7rem;
  font-weight: bold;
  cursor: pointer;
}

.fab-btn span {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease;
}

/* Equal-height card images, cf. https://stackoverflow.com/a/47698201/1375163*/
.card-img-top {
    /*height: 11vw;*/
    object-fit: cover;
    height: 100%;
}

.iti {
  width: 99.5% !important;
}
@media (min-width: 500px) {
  .iti__country-list {
    width: 300px !important;
    max-height: 200px !important;
    z-index: 100 !important;
  }
}
.iti__country-list{
    background-color: #534d9f !important;
    color: #f4f4f4 !important;
    border-radius: 2px;
    border-color: rgb(53, 57, 87);
    overflow-x: hidden;
}
.iti__dial-code {
  color: #f4f4f4 !important;
}

.iti-mobile .iti--container {
  top: 90px;
  bottom: 80px;
  left: 30px;
  right: 30px;
  position: fixed;
}
.iti-mobile .iti__country-list {
    width: 85% !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f4f4f4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
