<template>
  <!-- Begin page -->
  <div class="accountbg">
    <div class="wrapper-page">
      <div class="card card-pages shadow-none" :class="temaVisual === 'dark' ? 'is-dark' : ''">
        <div class="card-body">
          <div class="text-center m-t-0 m-b-15">
            <a href="#" class="logo logo-admin">
              <img
                :src="temaVisual === 'dark' ? urlLogoDark : urlLogo"
                style="max-height: 100px"
                alt=""
                height="100"
              />
            </a>
          </div>
          <h5 class="font-18 text-center">{{ $t("register.title") }}</h5>
          <b-list-group
            v-if="dispositivo"
            variant="dark"
            class="shadow"
            flush
          >
            <b-list-group-item class="flex-column align-items-start">
              <b-avatar
                badge
                :badge-variant="`${
                  dispositivo.estado === 'A' ? 'success' : 'danger'
                }`"
                variant="info"
                class="mr-3"
              >
                <img
                  :src="
                    urlImagenes + '/tiposDispositivo/' + dispositivo.imagen
                  "
                  style="max-width: 60px"
                  class="rounded-circle"
                />
              </b-avatar>
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{ dispositivo.id_dispositivo }}</h5>
                <small>{{ dispositivo.cantidad_dispositivos }}</small>
              </div>

              <p class="mb-1">
                {{ dispositivo.descripcion }}
              </p>
            </b-list-group-item>
          </b-list-group>

          <b-alert
              :show="!!msgAlert && msgAlert.length > 0"
              variant="danger"
              dismissible
              fade
              @dismissed="msgAlert = ''"
              >
            <span v-html="msgAlert"></span>
          </b-alert>
          
          <b-tabs v-model="tabIndex" v-if="dispositivo" class="pt-2">
            <b-tab :title="$t('register.toNewAccount')" active>  
              <form v-if="dispositivo" lass="form-horizontal m-t-30" @submit.prevent="onSubmit">
                
                <div class="form-group">
                  <div class="col-12">
                    <label>{{ $t('register.name') }}</label>
                    <input
                      class="form-control"
                      :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''"
                      type="text"
                      required=""
                      placeholder=""
                      v-model="model.name"
                    />
                  </div>
                </div>
                
                <div class="form-group">
                  <div class="col-12">
                    <label>{{ $t('register.email') }}</label>
                    <input
                      class="form-control"
                      :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''"
                      type="text"
                      required=""
                      placeholder="Email"
                      v-model="model.email"
                    />
                  </div>
                </div>

                <div class="form-group" :class="temaVisual === 'dark' ? 'is-dark' : ''">
                  <div class="col-12">
                    <label>{{ $t('register.password') }}</label>
                    <div class="input-group">
                      <input
                        :name="$t('register.password')"
                        v-model="model.password"
                        class="form-control"
                        :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''"
                        :type="showPassword ? 'text' : 'password'"
                        placeholder=""
                        required
                      />
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary" type="button" @click="togglePassword"><i :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i></button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="form-group">
                  <div class="col-12">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck1"
                      />
                      <label
                        class="custom-control-label font-weight-normal"
                        for="customCheck1"
                        >I accept
                        <a href="#" class="text-primary"
                          >Terms and Conditions</a
                        ></label
                      >
                    </div>
                  </div>
                </div> -->

                <div class="form-group text-center m-t-20">
                  <div class="col-12">
                    <button
                      class="btn btn-primary btn-block btn-lg waves-effect waves-light"
                      type="submit"
                    >
                      {{ $t('register.createAccount') }}
                    </button>
                  </div>
                </div>

              </form>
            </b-tab>
            <b-tab :title="$t('register.toAlreadyAccout')">
              <form v-if="dispositivo" lass="form-horizontal m-t-30" @submit.prevent="onSubmitOld">
                
                <div class="form-group">
                  <div class="col-12">
                    <label>{{ $t('register.email') }}</label>
                    <input
                      class="form-control"
                      :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''"
                      type="text"
                      required=""
                      placeholder="Email"
                      v-model="modelOld.email"
                    />
                  </div>
                </div>

                <div class="form-group" :class="temaVisual === 'dark' ? 'is-dark' : ''">
                  <div class="col-12">
                    <label>{{ $t('register.password') }}</label>
                    <div class="input-group">
                      <input
                        :name="$t('register.password')"
                        v-model="modelOld.password"
                        class="form-control"
                        :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''"
                        :type="showPasswordOld ? 'text' : 'password'"
                        placeholder=""
                        required
                      />
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary" type="button" @click="togglePasswordOld"><i :class="showPasswordOld ? 'fa fa-eye' : 'fa fa-eye-slash'"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group text-center m-t-20">
                  <div class="col-12">
                    <button
                      class="btn btn-primary btn-block btn-lg waves-effect waves-light"
                      type="submit"
                    >
                      {{ $t('register.assign') }}
                    </button>
                  </div>
                </div>

              </form>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <!-- END wrapper -->
  </div>
</template>
<script>
import md5 from "js-md5"
import { nanoid } from "nanoid"
import { mapMutations, mapGetters } from "vuex"
import Cookie from "js-cookie"

export default {
  name: "register",
  data () {
    return {
      dispositivo: null,
      model: {
        name: "",
        email: "",
        password: "",
      },
      showPassword: false,
      modelOld: {
        email: "",
        password: "",
      },
      showPasswordOld: false,
      msgAlert: "",
      tabIndex: 0,
    }
  },
  computed: {
    ...mapGetters({
      temaVisual: "getTemaVisual",
      parametrosGenerales: "getParametrosGenerales",
      urlImagenes: "getUrlImagenes",
      urlAcciones: "getUrlAcciones",
      urlLogo: 'getUrlLogo',
      urlLogoDark: 'getUrlLogoDark',
    }),
  },
  async mounted () {
    const { id } = this.$route.params
    const dispositivo = await this.$api.ejecutar("dispositivo_id_sel", [id])
    if (!!dispositivo && !!dispositivo[0]) {
      this.dispositivo = { ...dispositivo[0] }
    } else {
      this.msgAlert = this.$t('register.unassigned')
    }
  },
  methods: {
    ...mapMutations(["setUsuario"]),
    async onSubmit () {
      const reglasPassword = [(v) => !!v || this.$t('login.passwordRequired')]
      const reglasEmail = [
        (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('login.emailValid'),
      ]
      let validEmail = ''
      reglasEmail.forEach((rule) => {
        validEmail = rule(this.model.email)
      })
      if (validEmail !== true) {
        this.msgAlert = validEmail
        return
      }
      let validPassword = ''
      reglasPassword.forEach((rule) => {
        validPassword = rule(this.model.password)
      })
      if (validPassword !== true) {
        this.msgAlert = validPassword
        return
      }
    
      const token = nanoid()
      const hash = md5.create()
      hash.update(this.model.password)

      try {
        const response = await this.$api.ejecutar(
          "registrar_asignar",
          [
            token,
            this.dispositivo.id_dispositivo,
            this.model.name,
            this.model.email,
            hash.hex(),
          ],
          0,
        )

        if (response.length > 0) {
          if (response[0].status !== null && response[0].status === "ERROR") {
            this.msgAlert = response[0].message
            return
          }

          const user = {
            ...response[0],
            token,
          }
          this.setUsuario(user)
          Cookie.set("btapUsrTk", token)
          this.$router.push("/devices")
        }
      } catch (e) {
        this.msgAlert = this.$t("errors.networkError")
      }
    },
    async onSubmitOld () {
      const reglasPassword = [(v) => !!v || this.$t('login.passwordRequired')]
      const reglasEmail = [
        (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('login.emailValid'),
      ]
      let validEmail = ''
      reglasEmail.forEach((rule) => {
        validEmail = rule(this.modelOld.email)
      })
      if (validEmail !== true) {
        this.msgAlert = validEmail
        return
      }
      let validPassword = ''
      reglasPassword.forEach((rule) => {
        validPassword = rule(this.modelOld.password)
      })
      if (validPassword !== true) {
        this.msgAlert = validPassword
        return
      }
    
      const token = nanoid()
      const hash = md5.create()
      hash.update(this.modelOld.password)

      try {
        const response = await this.$api.ejecutar(
          "vincular_asignar",
          [
            token,
            this.dispositivo.id_dispositivo,
            this.modelOld.email,
            hash.hex(),
          ],
          0,
        )

        if (response.length > 0) {
          if (response[0].status !== null && response[0].status === "ERROR") {
            this.msgAlert = response[0].message
            return
          }

          const user = {
            ...response[0],
            token,
          }
          this.setUsuario(user)
          Cookie.set("btapUsrTk", token)
          this.$router.push("/devices")
        }
      } catch (e) {
        this.msgAlert = this.$t("errors.networkError")
      }
    },
    togglePassword () {
      this.showPassword = !this.showPassword
    },
    togglePasswordOld () {
      this.showPasswordOld = !this.showPasswordOld
    },
  },
}
</script>
