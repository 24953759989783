/* eslint-disable no-shadow */
const state = {
  tipoDispositivoSeleccionado: null,
  tipoDispositivoSeleccionadoIndex: null,
}
const getters = {
  tipoDispositivoSeleccionado: (state) => state.tipoDispositivoSeleccionado,
  tipoDispositivoSeleccionadoIndex: (state) => state.tipoDispositivoSeleccionadoIndex,
}

const mutations = {
  setTipoDispositivoSeleccionado: (state, tipoDispositivo) => {
    state.tipoDispositivoSeleccionado = tipoDispositivo
  },
  setTipoDispositivoSeleccionadoIndex: (state, index) => {
    state.tipoDispositivoSeleccionadoIndex = index
  },
}

const actions = {
}

const moduloTiposDispositivo = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
export default moduloTiposDispositivo
