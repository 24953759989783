import axios from 'axios'
import NProgress from 'nprogress'
import '@/plugins/nprogress'

axios.interceptors.request.use(
  (config) => {
    NProgress.start()
    return config
  }, 
  (error) => Promise.reject(error),
)

axios.interceptors.response.use(
  (response) => {
    NProgress.done()
    return response
  }, 
  (error) => Promise.reject(error),
)

const axiosBus = {}

// eslint-disable-next-line no-shadow
axiosBus.install = (Vue) => {
  Vue.prototype.$axios = axios
}

export default axiosBus
