<template>
  <!-- Begin page -->
  <div id="wrapper" >
    <!-- Top Bar Start -->
    <div class="topbar">
      <!-- LOGO -->
      <div class="topbar-left" :class="temaVisual === 'dark' ? 'bg-btap-primary' : 'bg-btap-primary'">
        <a href="#" class="logo">
          <img :src="urlLogoDark" style="max-height: 100px;" alt="" height="40"/>
        </a>
      </div>

      <nav class="navbar-custom" :class="temaVisual === 'dark' ? 'bg-btap-primary' : ''">
        <ul class="navbar-right list-inline float-right mb-0">
          <!-- language-->
          <!-- <li
            class="dropdown notification-list list-inline-item d-md-inline-block"
            :class="temaVisual === 'dark' ? 'is-dark' : ''"
          >
            <a
              class="nav-link dropdown-toggle arrow-none waves-effect"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <img
                :src="`assets/images/flags/${lenguaje}_flag.jpg`"
                class="mr-2"
                height="12"
                alt=""
              />
              <span v-if="$mq !== 'mobile'">{{ $t('global.' + lenguaje) }}</span> <span class="mdi mdi-chevron-down"></span>
            </a>
            <div
              class="is-dark dropdown-menu dropdown-menu-right dropdown-menu-animated language-switch"
              :class="temaVisual === 'dark' ? 'is-dark' : ''"
            >
              <a class="dropdown-item" :class="temaVisual === 'dark' ? 'is-dark' : ''" href="#" @click="setLanguage('es')"
                ><img
                  :src="`assets/images/flags/es_flag.jpg`"
                  alt=""
                  height="16"
                /><span>{{ $t('global.es') }}</span></a
              >
              <a class="dropdown-item" :class="temaVisual === 'dark' ? 'is-dark' : ''" href="#" @click="setLanguage('en')"
                ><img
                  :src="`assets/images/flags/en_flag.jpg`"
                  alt=""
                  height="16"
                /><span>{{ $t('global.en') }}</span></a
              >
            </div>
          </li> -->
          
          <!-- claro/oscuro -->
          <!-- <li
            class="dropdown notification-list list-inline-item d-md-inline-block"
            :class="temaVisual === 'dark' ? 'is-dark' : ''"
          >
            <a
              class="nav-link dropdown-toggle arrow-none waves-effect"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <b-icon :icon="temaVisual === 'dark' ? 'brightness-high-fill' : 'brightness-high'"></b-icon>
              <span v-if="$mq !== 'mobile'">{{ $t('global.' + temaVisual) }}</span> <span class="mdi mdi-chevron-down"></span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right dropdown-menu-animated language-switch"
              :class="temaVisual === 'dark' ? 'is-dark' : ''"
            >
              <a class="dropdown-item" href="#" :class="temaVisual === 'dark' ? 'is-dark' : ''" @click="setTheme('light')"
                >
                <b-icon icon="brightness-high"></b-icon><span>{{ $t('global.light') }}</span></a
              >
              <a class="dropdown-item" href="#" :class="temaVisual === 'dark' ? 'is-dark' : ''" @click="setTheme('dark')"
                ><b-icon icon="brightness-high-fill"></b-icon><span>{{ $t('global.dark') }}</span></a
              >
            </div>
          </li> -->

          <!-- full screen -->
          <!-- <li
            class="dropdown notification-list list-inline-item d-none d-md-inline-block"
          >
            <a class="nav-link waves-effect" href="#" id="btn-fullscreen">
              <i class="mdi mdi-arrow-expand-all noti-icon"></i>
            </a>
          </li> -->

          <!-- notification -->
          
          <li class="dropdown notification-list list-inline-item">
            <div class="dropdown notification-list nav-pro-img" :class="temaVisual === 'dark' ? 'is-dark' : ''">
              <a
                class="dropdown-toggle nav-link arrow-none nav-user"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <img
                  :alt="usuario.nombre" 
                  :src="usuario && usuario.foto ? urlImagenes + '/usuarios/' + usuario.foto + '?' + (new Date()).getTime() : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'"
                  onerror="this.src='https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'"
                  class="rounded-circle"
                />
              </a>
              <div class="dropdown-menu dropdown-menu-right profile-dropdown" :class="temaVisual === 'dark' ? 'is-dark' : ''">
                <!-- item-->
                <a class="dropdown-item" href="#" @click="$router.push('/profile')"
                  :class="temaVisual === 'dark' ? 'is-dark' : ''"
                ><i class="mdi mdi-account-circle"></i> {{$t('users.title')}}</a>
                <!-- <a class="dropdown-item" href="#"
                  ><i class="mdi mdi-wallet"></i> Wallet</a
                >
                <a class="dropdown-item d-block" href="#"
                  ><span class="badge badge-success float-right">11</span
                  ><i class="mdi mdi-settings"></i> Settings</a
                >
                <a class="dropdown-item" href="#"
                  ><i class="mdi mdi-lock-open-outline"></i> Lock screen</a
                >
                <div class="dropdown-divider"></div> -->
                <a class="dropdown-item text-danger" :class="temaVisual === 'dark' ? 'is-dark' : ''" href="#" @click="logout"
                  ><i class="mdi mdi-power text-danger"></i> Logout</a
                >
              </div>
            </div>
          </li>
        </ul>

        <ul class="list-inline menu-left mb-0">
          <li class="float-left">
            <button id="hamburguesa" class="button-menu-mobile open-left waves-effect" :class="temaVisual === 'dark' ? 'is-dark bg-btap-primary' : ''" >
              <i class="mdi mdi-menu"></i>
            </button>
          </li>
          <!-- <li class="d-none d-md-inline-block">
            <form role="search" class="app-search">
              <div class="form-group mb-0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search.."
                />
                <button type="submit"><i class="fa fa-search"></i></button>
              </div>
            </form>
          </li> -->
        </ul>
      </nav>
    </div>
    <!-- Top Bar End -->

    <!-- ========== Left Sidebar Start ========== -->
    <div class="left side-menu bg-btap-primary" :class="temaVisual === 'dark' ? 'is-dark' : ''">
      <div class="slimscroll-menu" id="remove-scroll">
        <!--- Sidemenu -->
        <div id="sidebar-menu" :class="temaVisual === 'dark' ? 'is-dark' : ''">
          <!-- Left Menu Start -->
          <ul class="metismenu" :class="temaVisual === 'dark' ? 'is-dark' : ''" id="side-menu">
            <li class="menu-title">Menu</li>
            <li >
              <a href="#" @click="go('/dashboard')">
                <!-- <img src="images/dashboard-icon.svg" width="22px" style="filter: invert(1);"> -->
                <!-- <span class="badge badge-success badge-pill float-right">9+</span> -->
                <i class="fa fa-tachometer-alt  mr-2"></i>
                <span>{{ $t('menu.dashboard') }}</span>
              </a>
            </li>

            <li >
              <a href="#" @click="go('/devices')">
                <!-- <img src="images/dispositivos-icon.svg" width="18px" style="filter: invert(1);"> -->
                <!-- <span class="badge badge-success badge-pill float-right">9+</span> -->
                <i class="fa fa-sd-card  mr-1 ml-1 disp"></i>
                <span>{{ $t('menu.devices') }}</span>
              </a>
            </li>
            <li><hr class="mb-3" /></li>
            <li>
                <a href="#" class="waves-effect" @click="showModalLenguaje = true">
                  <i class="fa fa-flag  mr-1 ml-1"></i>
                  <span>{{ $t('global.' + lenguaje) }}
                    <!-- <span class="float-right menu-arrow"> -->
                    <!-- <i class="mdi mdi-chevron-right"></i> -->
                    <!-- </span>  -->
                  </span>
                </a>
                <!-- <ul class="submenu">
                    <li>
                      <a :class="temaVisual === 'dark' ? 'is-dark' : ''" href="#" @click="setLanguage('es')">
                        <img
                          :src="`assets/images/flags/es_flag.jpg`"
                          class="mr-1"
                          alt=""
                          height="16"
                        />
                        <span>{{ $t('global.es') }}</span>
                      </a>
                    </li>
                    <li>
                      <a :class="temaVisual === 'dark' ? 'is-dark' : ''" href="#" @click="setLanguage('en')">
                        <img
                          :src="`assets/images/flags/en_flag.jpg`"
                          class="mr-1"
                          alt=""
                          height="16"
                        />
                        <span>{{ $t('global.en') }}</span>
                      </a>
                    </li>
                </ul> -->
            </li>

            <li>
              <a href="#" class="waves-effect" @click="showModalTema = true">
                <i :class="'fa ' + (temaVisual === 'dark' ? 'fa-adjust ' : 'fa-adjust ') +  ' mr-1 ml-1'"></i>
                <!-- <b-icon :icon="temaVisual === 'dark' ? 'brightness-high-fill' : 'brightness-high'" class="h4"></b-icon> -->
                <span>{{ $t('global.' + temaVisual) }}
                  <!-- <span class="float-right menu-arrow">
                  <i class="mdi mdi-chevron-right"></i>
                  </span>  -->
                </span>
              </a>
              <!-- <ul class="submenu">
                <li>
                  <a href="#" :class="temaVisual === 'dark' ? 'is-dark' : ''" @click="setTheme('light')">
                    <i class="fas fa-sun mr-1"></i><span>{{ $t('global.light') }}</span>
                  </a>
                </li>
                <li>
                  <a href="#" :class="temaVisual === 'dark' ? 'is-dark' : ''" @click="setTheme('dark')">
                    <i class="fas fa-moon mr-1"></i><span>{{ $t('global.dark') }}</span>
                  </a>
                </li>
              </ul> -->
            </li>

            <li >
              <a href="https://www.btap.com.gt/FAQ" target="_blank">
                <!-- <img src="images/dashboard-icon.svg" width="22px" style="filter: invert(1);"> -->
                <!-- <span class="badge badge-success badge-pill float-right">9+</span> -->
                <i class="fa fa-question-circle  mr-1 ml-1"></i>
                <span>{{ $t('menu.faq') }}</span>
              </a>
            </li>
          </ul>
        </div>
        <!-- Sidebar -->
        <div class="clearfix"></div>
      </div>
      <!-- Sidebar -left -->
    </div>
    <!-- Left Sidebar End -->
    <b-modal v-model="showModalLenguaje" :hide-footer="true" :hide-header="true"
      :body-bg-variant="temaVisual === 'dark' ? 'page' : 'btap-primary'"
      centered
    >
      <nav class="mb-3" >
        <b-nav vertical pills>
          <b-nav-item 
            class="border m-1 pr-2 pl-2 pb-1 pt-1 text-center text-white"
            @click="setLanguage('es'); showModalLenguaje = false"
            
          >
            <img
              :src="`assets/images/flags/es_flag.jpg`"
              class="mr-1"
              alt=""
              height="16"
            />
            <span class="mr-2 text-white" >{{ $t('global.es') }}</span>
          </b-nav-item>
          <b-nav-item 
            class="border m-1 pr-2 pl-2 pb-1 pt-1 text-center text-white"
            @click="setLanguage('en'); showModalLenguaje = false"
            
          >
            <img
              :src="`assets/images/flags/en_flag.jpg`"
              class="mr-1"
              alt=""
              height="16"
            />
            <span class="mr-2 text-white" >{{ $t('global.en') }}</span>
          </b-nav-item>
        </b-nav>
      </nav>
    </b-modal>

    <b-modal v-model="showModalTema" :hide-footer="true" :hide-header="true"
      :body-bg-variant="temaVisual === 'dark' ? 'page' : 'btap-primary'"
      centered
    >
      <nav class="mb-3" >
        <b-nav vertical pills>
          <b-nav-item 
            class="border m-1 pr-2 pl-2 pb-1 pt-1 text-center text-white"
            @click="setTheme('light'); showModalTema = false"
            
          >
            <i class="fas fa-sun mr-1"></i>
            <span class="mr-2 text-white" >{{ $t('global.light') }}</span>
          </b-nav-item>
          <b-nav-item 
            class="border m-1 pr-2 pl-2 pb-1 pt-1 text-center text-white"
            @click="setTheme('dark'); showModalTema = false"
            
          >
            <i class="fas fa-moon mr-1"></i>
            <span class="mr-2 text-white" >{{ $t('global.dark') }}</span>
          </b-nav-item>
        </b-nav>
      </nav>
    </b-modal>
    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-page" :class="temaVisual === 'dark' ? 'is-dark bg-page' : ''">
      <router-view />

      <footer class="footer" :class="temaVisual === 'dark' ? 'is-dark bg-objects text-white' : ''">
        © BTap
        <!-- <span class="d-none d-sm-inline-block">
          - Crafted with <i class="mdi mdi-heart text-danger"></i> by
          Themesdesign</span
        >. -->
      </footer>
    </div>
    <!-- ============================================================== -->
    <!-- End Right content here -->
    <!-- ============================================================== -->
  </div>
  <!-- END wrapper -->
</template>
<script>
import Cookie from 'js-cookie'
import { mapGetters, mapMutations } from "vuex"

export default {
  data () {
    return {
      showModalLenguaje: false,
      showModalTema: false,
    }
  },
  computed: {
    ...mapGetters({
      temaVisual: "getTemaVisual",
      lenguaje: "getLenguaje",
      usuario: "getUsuario",
      parametrosGenerales: "getParametrosGenerales",
      urlImagenes: "getUrlImagenes",
      urlLogo: 'getUrlLogo',
      urlLogoDark: 'getUrlLogoDark',
      // urldispositivos: "gerUrlDispositivos",
    }),
  },
  mounted () {
    // console.log('mainnavbar')
    this.$i18n.locale = this.lenguaje

    // if (window.width < 1025) {
    //   const body = document.getElementsByTagName("body")[0]
    //   body.addClass('enlarged')
    // }

    // eslint-disable-next-line no-undef
    if ($(window).width() < 480) {
      // eslint-disable-next-line no-undef
      $('body').addClass('enlarged')
    // eslint-disable-next-line no-undef
    } else if ($('body').data('keep-enlarged') !== true) {
      // eslint-disable-next-line no-undef
      $('body').removeClass('enlarged')
    }

    if (this.temaVisual === 'dark') {
      // eslint-disable-next-line no-undef
      $('body').addClass('bg-page')
    } else {
      // eslint-disable-next-line no-undef
      $('body').removeClass('bg-page')
    }

    // eslint-disable-next-line no-undef
    $("#side-menu").metisMenu()

    // eslint-disable-next-line func-names
    window.addEventListener('mouseup', function (event) {
      // eslint-disable-next-line no-var
      var box = document.getElementById('side-menu')
      // eslint-disable-next-line no-var
      var hamburguesa = document.getElementById('hamburguesa')
      // console.log('mouseup', box, hamburguesa, event.target, event.target.parentNode)
      if (event.target === hamburguesa || event.target.parentNode === hamburguesa) {
        // console.log("entra hamburguesa")
        // eslint-disable-next-line no-undef
        $("body").toggleClass("enlarged")
        return
      }
      if (event.target !== box && event.target.parentNode !== box) {
        // box.style.display = 'none'
        // eslint-disable-next-line no-undef
        if ($("body").hasClass("enlarged") === false) {
          // eslint-disable-next-line no-undef
          $("body").toggleClass("enlarged")
        }
        if (this.$mq === 'mobile' || this.$mq === 'tablet') {
          // eslint-disable-next-line no-undef
          $("body").toggleClass("enlarged")
        }
      }
    })
  },
  methods: {
    ...mapMutations([
      'setTemaVisual',
      'setLenguaje',
      'setUsuario',
    ]),
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    async logout () {
      await this.$api.ejecutar('logout', [this.usuario.token])
      this.setUsuario(null)
      Cookie.remove('btapUsrTk')
      this.$router.push('/') // se usa / en lugar de /login
    },
    setLanguage (lenguaje) {
      this.$i18n.locale = lenguaje
      this.setLenguaje(lenguaje)
      this.$api.ejecutar('lenguaje_usuario_u', [this.usuario.token, lenguaje], 0)
      // this.$forceUpdate()
      this.delayToggleMenu()
    },
    setTheme (tema) {
      this.setTemaVisual(tema)
      process.env.theme = tema
      if (this.temaVisual === 'dark') {
        // eslint-disable-next-line no-undef
        $('body').addClass('bg-page')
      } else {
        // eslint-disable-next-line no-undef
        $('body').removeClass('bg-page')
      }
      this.$api.ejecutar('tema_usuario_u', [this.usuario.token, tema], 0)
      this.delayToggleMenu()
    },
    go (path) {
      this.$router.push(path) 
      this.delayToggleMenu()
    },
    toggleMenu (event) {
      // const body = document.getElementsByTagName("body")[0]
      // body.toggleClass('enlarged')
      if (event) {
        event.preventDefault()
      }
      // console.log('entra togglemenu')
      // eslint-disable-next-line no-undef
      $("body").toggleClass("enlarged")
      this.$forceUpdate()
    },
    delayToggleMenu () {
      // setTimeout(() => {
      //   // eslint-disable-next-line no-undef
      //   // $('#hamburguesa').focus()
      //   // eslint-disable-next-line no-undef
      //   // $('#hamburguesa').click()
      //   // this.toggleMenu()
      // }, 3000)
      // console.log('delay', this.$mq)
      if (this.$mq === 'mobile' || this.$mq === 'tablet') {
        // console.log('toggle', this.$mq)
        // eslint-disable-next-line no-undef
        $('#hamburguesa').focus()
        // eslint-disable-next-line no-undef
        $('#hamburguesa').click()
      }
    },
  },
}
</script>
<style>
.enlarged #wrapper .left.side-menu #sidebar-menu > ul > li > a i.disp {
  margin-left: .5rem !important;
}
@media screen and (max-width: 500px) {
  .modal-dialog {
    margin: 3rem !important
  }
}
</style>
