<template>
    <!-- Start content -->
  <div class="content">
    <div class="container-fluid">
      <div class="page-title-box">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <h4 class="page-title">{{ $t('users.title') }}</h4>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end page-title -->
        <b-alert 
          :show="!!msgInfo && msgInfo.length > 0" 
          variant="info" 
          dismissible 
          fade
          @dismissed="msgInfo = ''"
        >
          {{ msgInfo }}
        </b-alert>
        <b-alert 
          :show="!!msgAlert && msgAlert.length > 0" 
          variant="danger" 
          dismissible 
          fade
          @dismissed="msgAlert = ''"
        >
          {{ msgAlert }}
        </b-alert>
        <b-form @submit.prevent="save">
          <b-row>
            <b-col md="6" sm="12">
              <b-form-group 
                :label="$t('users.name')"
                label-for="input-name"
                class="form-control-label ml-3 mr-2"
              >
                <b-form-input
                  class="mb-3 ml-1"
                  :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : 'is-light bg-transparent'"
                  name="input-name"
                  id="input-name"
                  type="text"
                  required
                  placeholder=""
                  v-model="usuario.nombre"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group 
                :label="$t('users.email')"
                label-for="input-email"
                class="form-control-label ml-3 mr-2"
              >
                <b-form-input
                  class="mb-3 ml-1"
                  :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : 'is-light bg-transparent'"
                  name="input-email"
                  id="input-email"
                  type="email"
                  required
                  placeholder=""
                  v-model="usuario.correo"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group 
                :label="$t('users.password')"
                label-for="input-password"
                class="form-control-label ml-3 mr-2"
              >
                <b-input-group size="sm" class="mb-3">
                  <b-form-input
                    class="mb-3 ml-1"
                    :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : 'is-light bg-transparent'"
                    name="input-password"
                    id="input-password"
                    :type="showPassword ? 'text' : 'password'"
                    placeholder=""
                    v-model="usuario.clave"
                  >
                  </b-form-input>
                  <template #append>
                    <b-button
                      size="sm"
                      class="mb-3"
                      variant="outline-secondary"
                      @click="togglePassword"
                    >
                      <i :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                    </b-button>
                  </template>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12" :class="$mq === 'mobile' ? 'order-first' : ''">

              <b-form-group 
                    :label="$t('users.photo')"
                    label-for="input-photo"
                    class="form-control-label ml-3 mr-2 dragdrop"
                  >
                <div class="embed-responsive embed-responsive-16by9 border-1 mb-3 ml-1" :class="temaVisual === 'dark' ? 'is-dark text-muted rounded' : 'rounded'" style="max-height: 150px;" :style="$mq === 'mobile' ? 'max-width: 100%' : 'width: 165px'">
                  <div class="card embed-responsive-item"  >
                    <!-- <label v-if="usuario && (files || usuario.foto)" 
                      class="fab-btn bg-danger" 
                      style="background-color: rgba(0,0,0,0.1) !important"
                      @click.prevent="files = null; usuario.foto = ''">
                      <span><i class="fa fa-times"></i></span>
                    </label> -->
                    <img
                      v-if="usuario && (files || usuario.foto)" 
                      class="card-img-top" 
                      :src="!!files && files !== null ? files.url : usuario && usuario.foto ? urlImagenes + '/usuarios/' + usuario.foto + '?' + (new Date()).getTime() : 'images/noimage.png'"
                      onerror="this.src='images/noimage.png'"
                      style="object-fit: cover;"
                    >
                    <div v-if="!files && !usuario.foto" 
                      class="card-img-overlay d-flex flex-column justify-content-center text-center" 
                      :style="temaVisual === 'dark' ? 'background-color: rgba(46,48,74,0.8) !important' : 'background-color: rgba(244,244,244, 0.8)'" 
                      @click.prevent="clickFoto('foto')">
                      <label class="label card-title mt-1" >{{ $t('global.clickToUpload') }}</label>
                    </div>
                  </div>
                </div>
                <b-button v-if="usuario && (files || usuario.foto)" 
                  variant="transparent"
                  class="mb-3 ml-1 border-0 text-muted"
                  :block="$mq === 'mobile'"
                  @click.prevent="files = null; usuario.foto = ''">
                  <span><i class="fa fa-trash-alt mr-1"></i>{{ $t('global.clearImage') }}</span>
                </b-button>
                <b-form-file 
                  name="foto" 
                  id="foto" 
                  ref="foto" 
                  v-model="files" 
                  class="btn btn-primary d-none" 
                  plain
                  @input="filesChange"  
                >
                </b-form-file>
              </b-form-group>

            </b-col>
          </b-row>

            <b-button 
              class="mr-2" 
              :loading="loading" 
              :disabled="!valid || valid === false || loading" 
              :class="!valid || valid === false || loading ? 'text-muted' : ''" 
              :block="$mq === 'mobile'"
              type="submit" 
              variant="primary">
              <b-icon icon="upload" class="font-weight-bolder mr-2"></b-icon>&nbsp;{{ $t('global.update') }}
            </b-button>
            <b-button 
              @click="back" 
              type="warning" 
              :icon="true"
              :block="$mq === 'mobile'">
              <b-icon icon="backspace" class="font-weight-bolder mr-2"></b-icon>&nbsp;{{ $t('global.cancel') }}
            </b-button>
        </b-form>
      </div>
    <!-- container-fluid -->
  </div>
  <!-- content -->
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { nanoid } from 'nanoid'
import md5 from 'js-md5'

export default {
  data () {
    return {
      usuario: {},
      files: null,
      showPassword: false,
      msgAlert: '',
      msgInfo: '',
      loading: false,
      valid: true,
      telefono: '',
    }
  },
  computed: {
    ...mapGetters({
      temaVisual: 'getTemaVisual',
      lenguaje: 'getLenguaje',
      usuarioLogged: 'getUsuario',
      parametrosGenerales: 'getParametrosGenerales',
      urlImagenes: 'getUrlImagenes',
      urlAcciones: 'getUrlAcciones',
      urlLogo: 'getUrlLogo',
      urlLogoDark: 'getUrlLogoDark',
    }),
  },
  mounted () {
    this.usuario = JSON.parse(JSON.stringify(this.usuarioLogged))
    // console.log('foto ', this.$refs)
  },
  methods: {
    ...mapMutations([
      'setUsuario',
    ]),
    async save (saveNew = false) {
      const reglasNombre = [(v) => !!v || this.$t('users.nameRequired')]
      const reglasEmail = [
        (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('users.emailRequired'),
      ]
      let validEmail = ''
      reglasEmail.forEach((rule) => {
        validEmail = rule(this.usuario.correo)
      })
      if (validEmail !== true) {
        this.msgAlert = validEmail
        return
      }
      let validNombre = ''
      reglasNombre.forEach((rule) => {
        validNombre = rule(this.usuario.nombre)
      })
      if (validNombre !== true) {
        this.msgAlert = validNombre
        return
      }
      try {
        this.loading = true
        if (!!this.files && !!this.files !== null) {
          if (!this.usuario.foto || this.usuario.foto === null) {
            // genera un random para el nombre del archivo
            this.usuario.foto = nanoid() + this.files.extension
          } else {
            // verifica si es diferente extension
            const fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi
            const oldExt = this.usuario.foto.match(fileExtensionPattern)[0]
            if (this.files.extension !== oldExt) {
              this.usuario.foto.replace(oldExt, this.files.extension)
            }
          }
        }
        const hash = md5.create()
        if (this.usuario.clave) {
          hash.update(this.usuario.clave)
        }
        const params = [
          this.usuarioLogged.token,
          this.usuario.nombre,
          this.usuario.correo,
          this.usuario.clave ? hash.hex() : null,
          this.usuario.foto,
        ]
        const response = await this.$api.ejecutar('actualiza_perfil', params)
        // console.log('response save ', response)
        this.loading = false
        if (Array.isArray(response)) {
          if (!!response[0] && response[0].status !== null && response[0].status === 'ERROR') {
            // console.log('response existe error ', response[0])
            this.msgAlert = response[0].message
            return
          } 
          // console.log('no existe Error')
          const resultUpload = await this.uploadImage(this.usuario.foto)
          // console.log('resultUpload ', resultUpload)
          if (resultUpload.status === 'ERROR') {
            this.msgAlert = resultUpload.msgAlert
            return
          }
          // envía el correo informando la actualizacion de datos
          const datosCorreo = {
            welcomeTitle: this.$t('users.mailUpdateProfileTitle'),
            message: this.$t('users.mailUpdateProfileMessage'),
            // actionTitle: this.$t('users.mailActionTitle'),
            // urlAction: `${this.urlClave}/${tokenTemporal}`, 
            _correo: this.usuario.correo,
            _asunto: this.$t('users.mailUpdateProfileSubject'),
          }
          // console.log('datos correo ', datosCorreo)
          const responseMail = await this.$api.notify(datosCorreo)

          // actualiza los datos de la sesion actual
          const responseDatosSesion = await this.$api.ejecutar('datos_sesion', [this.usuarioLogged.token], 0)
          // console.log('response sesion ', responseDatosSesion)  
          if (Array.isArray(responseDatosSesion)) {
            if (responseDatosSesion[0].status !== null && responseDatosSesion[0].status === 'ERROR') {
              // do nothing
            } else {
              const user = {
                ...responseDatosSesion[0],
                token: this.usuarioLogged.token,
              }
              // console.log('user sesion ', user)
              this.setUsuario(user)    
            }
          }
          
          // console.log('testSaveNew ', saveNew)
          this.msgInfo = this.$t('users.profileUpdated')
        }
      } catch (e) {
        // console.log('Error remove ', e)
        this.loading = false
        this.msgAlert = this.$t('errors.networkError')
      }
    },
    filesChange (evt) {
      // console.log('files ', typeof this.files, this.files)
      const fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi
      if (!!this.files && this.files !== null) {
        if (typeof this.files === 'object') {
          if (this.files.size / 1024 / 1024 > 2) {
            // console.log('Image Size: ', this.files.size/1024/1024)
            this.msgAlert = this.$t('global.maxImageSize')
            this.files = null
            return
          }
          this.files.url = URL.createObjectURL(this.files)
          const extension = this.files.name.match(fileExtensionPattern)[0]
          this.files.extension = extension
        }
        if (Array.isArray(this.files)) {
          this.files.forEach((element) => {
            element.url = URL.createObjectURL(this.files)
            const extension = element.name.match(fileExtensionPattern)[0]
            element.extension = extension
          })
        }
      }
    },
    async uploadImage (name) {
      // console.log('realiza upload')
      try {
        this.loading = true
        if (!!this.files && !!this.files !== null) {
          // realiza el upload de la imagen
          const formEnviarDocumento = new FormData()
          // debugger
          formEnviarDocumento.append('file', this.files)
          formEnviarDocumento.append('logico', name)
          formEnviarDocumento.append("subpath", 'usuarios/') 
          // console.log('cargando imagen')
          const responseUpload = await this.$api.upload(formEnviarDocumento)
          // console.log('responseUpload ', responseUpload)
          if (Array.isArray(responseUpload)) {
            // debugger
            if (!!responseUpload[0] && responseUpload[0].status === 'ERROR') {
              // console.log(`Hubo un error al guardar el ${this.files.name} en el servidor. Verifique el documento que desea guardar`)
              return { status: 'ERROR', message: this.$t('errorUploadImage', { label: this.files.name }) }
            }
          }
        }
        return { status: 'OK', message: '' }
      } catch (e) {
        this.loading = false
        // console.log('Error remove ', e)
        this.msgAlert = this.$t('errors.networkError')
        return { status: 'ERROR', message: this.$t('errors.networkError') }
      }
    },
    togglePassword () {
      this.showPassword = !this.showPassword
    },
    back () {
      this.$router.push('/dashboard')
    },
    clickFoto (id) {
      const foto = document.getElementById(id)
      foto.click()
    },
  },
}
</script>
<style scoped>
.fab-btn {
  position: absolute;
  /* bottom: 12px; */
  right: -1px;
  border-radius: 10%;
  width: 50px;
  height: 50px;
  transition: box-shadow 0.4s ease;
  /* background: #4610f5; */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  color: #f4f4f4;
  font-size: 1.7rem;
  font-weight: bold;
  cursor: pointer;
}

.fab-btn span {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease;
}

/* Equal-height card images, cf. https://stackoverflow.com/a/47698201/1375163*/
.card-img-top {
    /*height: 11vw;*/
    object-fit: cover;
    height: 100%;
}

</style>
