import Vue from 'vue'      
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import '@/plugins/nprogress'
import Cookie from 'js-cookie'
                           
import store from '../store'
import coreApi from '../plugins/services'
import routes from './routes'
                                           
Vue.use(VueRouter)      

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

// router guards
router.beforeEach(async (to, from, next) => {
  NProgress.start()
  // console.log('to ', to)

  // Obtiene el token de las cookies
  const token = Cookie.get('btapUsrTk')
  // console.log('token ', token)
  if (to.path === '/error') {
    next()
    return
  }
  if (!!token && token !== null && token.length > 0) {
    // si el token existe verifica si hay datos de sesion
    // en el store para reconsultar los datos desde la db
    if (store.getters.getUsuario === null) {
      // console.log('no existe usuario busca la sesion')
      try {
        const response = await coreApi
          .ejecutar('datos_sesion', [token], 0)
        // console.log('response sesion ', response)  
        if (Array.isArray(response)) {
          if (response[0].status !== null && response[0].status === 'ERROR') {
            // do nothing
          } else {
            const user = {
              ...response[0],
              token,
            }
            // console.log('user sesion ', user)
            store.commit('setUsuario', user)    
          }
        }
      } catch (error) {
        // error sin conexion a la base de datos
        next({                          
          path: '/error',               
          // query: { redirect: to.fullPath },
        })
      }
    }
  }
  let parametrosGenerales = JSON.parse(JSON.stringify(store.getters.getParametrosGenerales))
  if (Object.keys(parametrosGenerales).length <= 0) {
    // no hay definidos parametros entonces se consultan de la db 
    try {
      const response = await coreApi
        .ejecutar('parametros_generales_sel', [], 0)
      // console.log('response sesion ', response)  
      if (Array.isArray(response)) {
        if (response[0].status !== null && response[0].status === 'ERROR') {
          // do nothing
        } else {
          parametrosGenerales = {
            ...response[0],
          }
          // console.log('parametros generales ', parametrosGenerales)
          store.commit('setParametrosGenerales', parametrosGenerales)    
        }
      }
    } catch (error) {
      // error sin conexion a la base de datos
      next({                          
        path: '/error',               
        query: { redirect: to.fullPath },
      })
    }
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) { 
    const user = store.getters.getUsuario
    // console.log('user store ', user)
    if (user === null) {
      // redirige a login
      next({                          
        path: '/',               
        query: { redirect: to.fullPath },
      })                              
    } else {
      // Se debe validar si el usuario tiene permisos
      // para la ruta
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

router.afterEach((to, from) => {
  NProgress.done()
})

export default router
