<template>
  <!-- Start content -->
  <div class="content">
    <div class="container-fluid">
      <div class="page-title-box">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <h4 class="page-title">{{ $t('devices.title') }}</h4>
          </div>
          <!-- <div class="col-sm-6">
            <ol class="breadcrumb float-right">
              <li class="breadcrumb-item">
                <a href="javascript:void(0);">Stexo</a>
              </li>
              <li class="breadcrumb-item active">Dashboard</li>
            </ol>
          </div> -->
        </div>
        <!-- end row -->
      </div>
      <!-- end page-title -->
      <!-- :bg-variant="temaVisual === 'dark' ? 'page' : 'btap-primary'" -->
      <b-sidebar 
      id="sidebar-acciones" 
      v-model="showAcciones"
      shadow 
      class="text-center "
      :class="[$mq === 'mobile' ? 'sidebar-acciones-full' : 'sidebar-acciones-full']" 
      :bg-variant="temaVisual === 'dark' ? 'objects' : 'btap-primary'"
      text-variant="white"
      >
      <template #title>
        <span class="text-white">{{ $t('devices.actionTitle') }}</span>
      </template>
      <!-- <template #default="{ hide }"> -->
      <nav class="mb-3" >
        <b-nav vertical pills class="ml-3 mr-3">
          <b-nav-item 
            v-for="ac in itemsAcciones" 
            class="border m-1 pr-2 pl-2 pb-1 pt-1 text-center text-white"
            :key="ac.id_accion"
            @click="seleccionaAccion(ac);"
            :class="[ accionSeleccionada && accionSeleccionada.id_accion === ac.id_accion ? 'border-secondary' : 'border-white', temaVisual === 'dark' ? 'bg-objects' : 'bg-btap-primary']"
          >
            <i :class="`fa ${ac.imagen} mr-2 text-white`" ></i>
            <span class="mr-2 text-white" >{{ $t(`devices.actionsLabels.${ac.id_accion}`) }}</span>
          </b-nav-item>
        </b-nav>
      </nav>
      <!-- </template> -->
     </b-sidebar>

      <b-row>
        <b-col v-for="(item, indexItem) in items" :key="item.id_dispositivo" xs="12" sm="12" md="6" lg="4">
          <b-card no-body class="overflow-hidden p-2" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : 'shadow rounded'" style="border-radius: 15px !important;">
            <b-row>
              <b-col xs="7">
                <b-row>
                  <b-col>
                    <img :src="temaVisual === 'dark' ? urlLogoDark : urlLogo" alt="BTap" class="rounded-0 p-1" width="100">
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <!-- <h5>{{ item.id_dispositivo }}</h5> -->
                    <h6 class="text-truncate" style="max-width: 120px;">{{ item.alias ? item.alias : item.id_dispositivo }}</h6>
                    <!-- <b-form-input
                      class="mb-3 ml-1"
                      :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : 'is-light bg-transparent'"
                      name="input-alias"
                      id="input-alias"
                      type="text"
                      size="sm"
                      :placeholder="$t('devices.alias')"
                      @change="updateAlias(item)"
                      @keyup.enter="updateAlias(item)"
                      v-model="item.alias"
                    >
                    </b-form-input> -->
                    <span>{{ item.id_accion_principal ? $t(`devices.actionsLabels.${item.id_accion_principal}`) : ''}}</span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col xs="5">
                <b-row>
                  <b-col>
                    <button
                      @click="configuration(item)"
                      block
                      class="btn btn-md btn-block bg-btap-primary border-0 text-white"
                    >
                      <!-- <i class="fa fa-cog mr-2"></i> -->
                      {{ $t("devices.settings") }}
                    </button>
                    <button
                      class="btn btn-secondary btn-block no-border text-white"
                      size="md"
                      @click="descargarQR(item)"
                    >
                      <!-- <i class="fa fa-qrcode mr-3" v-b-tooltip.hover :title="$t('devices.downloadQR')" ></i> -->{{ $t('devices.downloadQR') }}
                    </button>
                  </b-col>
                  <!-- <b-col>
                    <b-button
                      @click="descargarQR(item)"
                      block
                      class="bg-btap-secondary m-1 border-0"
                      v-b-tooltip.hover :title="$t('devices.downloadQR')"
                    >
                      <i class="fa fa-qrcode mr-2"></i>
                      {{ $t("devices.downloadQR") }}
                    </b-button>
                  </b-col>
                  <b-col>
                    <b-button
                      @click="copiarQR(item)"
                      block
                      class="bg-black m-1 border-0"
                      v-b-tooltip.hover :title="$t('devices.copyURL')"
                    >
                      <i class="fa fa-clipboard mr-2"></i>
                      {{ $t("devices.copyURL") }}
                    </b-button>
                  </b-col> -->
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
              <!-- <i class="fa fa-clipboard fa-2x mr-3 float-right text-btap-primary" v-b-tooltip.hover :title="$t('devices.copyURL')" @click="copiarQR(item)"></i> -->
              <!-- <i class="fa fa-qrcode mr-3 float-right text-btap-secondary" v-b-tooltip.hover :title="$t('devices.downloadQR')" @click="descargarQR(item)"></i> -->
              <i class="fa fa-gear fa-2x mr-3 float-right text-btap-secondary" @click="alias(item, indexItem)"></i>
              </b-col>
            </b-row>
          </b-card>
          
        </b-col> 
      </b-row>

      </div>
    <!-- container-fluid -->
    <!-- :body-bg-variant="temaVisual === 'dark' ? 'page' : 'btap-primary'" -->
    <b-modal v-model="showModalAlias" :hide-footer="true" :hide-header="true"
      centered
      :body-bg-variant="temaVisual === 'dark' ? 'page' : ''"
      :class="temaVisual === 'dark' ? 'text-muted' : ''"
      class="p-0 border-0 card"
    >
      <!-- <b-card no-body class="overflow-hidden m-0 mb-3" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''"> -->
        <h6 class="pt-2" :class="temaVisual === 'dark' ? 'text-muted' : ''">{{ $t('devices.aliasTitle') }}</h6>
        <b-form-input
                  class="mb-3 mr-2"
                  :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : 'is-light bg-transparent'"
                  name="input-alias"
                  id="input-alias"
                  ref="inputAlias"
                  type="text"
                  size="sm"
                  :placeholder="$t('devices.alias')"
                  v-model="dispositivo.alias"
                  :autofocus="true"
                >
                </b-form-input>
          <b-button 
              class="mr-2" 
              :block="$mq === 'mobile'"
              @click="updateAlias(dispositivo)"
              variant="primary">
              <b-icon icon="upload" class="font-weight-bolder mr-2"></b-icon>&nbsp;{{ $t('global.update') }}
          </b-button>
          <b-button 
              class="mr-2"
              :block="$mq === 'mobile'"
              @click="showModalAlias = false; dispositivo = {}" 
              type="warning" 
              :icon="true"
              >
              <b-icon icon="backspace" class="font-weight-bolder mr-2"></b-icon>&nbsp;{{ $t('global.cancel') }}
          </b-button>
      <!-- </b-card> -->
    </b-modal>
  </div>
  <!-- content -->
</template>

<script>
import QRCode from 'qrcode'
import { mapGetters, mapMutations } from 'vuex'
// import Alias from '@/pages/Devices/Alias.vue'

const copyToClipboard = (str) => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export default {
  // components: {
  //   Alias,
  // },
  data () {
    return {
      loading: false,
      msgAlert: '',
      icon: "pe-7s-drawer icon-gradient bg-happy-itmeo",
      fields: [],
      items: [],
      itemsTiposDispositivo: [],
      filter: null,
      striped: true,
      bordered: false,
      outlined: false,
      small: false,
      hover: true,
      dark: false,
      fixed: false,
      footClone: false,
      responsive: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 15],
      itemsAcciones: [],
      showAcciones: false,
      showModalAlias: false,
      dispositivo: {},
      dispositivoIndex: 0,
    }
  },
  computed: {
    ...mapGetters({
      temaVisual: 'getTemaVisual',
      lenguaje: 'getLenguaje',
      usuario: 'getUsuario',
      parametrosGenerales: 'getParametrosGenerales',
      urlImagenes: 'getUrlImagenes',
      urlAcciones: 'getUrlAcciones',
      urlLogo: 'getUrlLogo',
      urlLogoDark: 'getUrlLogoDark',
    }),
    ...mapGetters('dispositivos', [
      'accionSeleccionada',
      'dispositivoSeleccionado',
    ]),
  },
  watch: {
    // lenguaje () {
    //   // console.log('cambiando lenguaje')
    //   // this.setFields()
    // },
    showAcciones () {
      if (this.showAcciones === true) {
        // eslint-disable-next-line no-undef
        // console.log("$mq ", this.$mq, $('#sidebar-acciones').css('width'))
        if (this.$mq === 'mobile') {
          // eslint-disable-next-line no-undef
          $('#sidebar-acciones').css('width', '120%')
          // eslint-disable-next-line no-undef
          // console.log("$mq mobile", $('#sidebar-acciones').css('width'))
        } else {
          // eslint-disable-next-line no-undef
          $('#sidebar-acciones').css('width', '')
          // eslint-disable-next-line no-undef
          // console.log("$mq otros", this.$mq, $('#sidebar-acciones').css('width'))
        }
      }
    },
  },
  async mounted () {
    this.itemsTiposDispositivo = await this.$api.ejecutar('CATALOGOS', ['TIPOS_DISPOSITIVO']) 
    this.loadData()
    window.scrollTo(0, 0)
  },
  methods: {
    ...mapMutations('dispositivos', [
      'setDispositivoSeleccionado',
      'setAccionSeleccionada',
    ]),
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    truncate (value) {
      if (value === null) return ""
      if (value.length < 30) return value
      return `${value.substring(1, 30)}...`
    },
    // setFields () {
    //   const fields = [
    //     { key: "id_dispositivo", label: this.$t("devices.id") },
    //     { key: "descripcion", label: this.$t("devices.deviceType") },
    //     { key: "es_masivo", label: this.$t("devices.isMassive") },
    //     { key: "cantidad_dispositivos", label: this.$t("devices.quantity") },
    //     {
    //       key: "estado",
    //       label: this.$t("devices.status"),
    //     },
    //     { key: "acciones", label: "" },
    //   ]
    //   this.fields = []
    //   fields.forEach((field) => {
    //     this.fields.push(field)
    //   })
    // },
    async loadData () {
      // console.log('loading devices ')
      this.loading = true
      try {
        this.items = []
        this.totalRows = 0
        const params = [
          this.usuario.token, 
        ]
        const response = await this.$api.ejecutar('dispositivos_sel', params)
        this.loading = false
        if (response.length > 0) {
          if (response[0].status !== null && response[0].status === 'ERROR') {
            this.msgAlert = response[0].message
            return
          } 
          response.forEach(async (item) => {
            item.srcQR = await QRCode.toDataURL(`${this.urlAcciones}/${item.id_dispositivo}`, { width: 512 })
          })
          this.items = response 
          this.totalRows = this.items.length
        }
        this.itemsAcciones = await this.$api.ejecutar("CATALOGOS", [
          "ACCIONES",
        ])
      } catch (e) {
        this.loading = false
        this.msgAlert = this.$t('errors.networkError')
      }
    },
    async updateAlias (item) {
      // console.log('updating alias ')
      this.loading = true
      try {
        const params = [
          this.usuario.token, 
          item.id_dispositivo,
          item.alias,
        ]
        const response = await this.$api.ejecutar('actualiza_alias', params)
        this.loading = false
        if (response.length > 0) {
          if (response[0].status !== null && response[0].status === 'ERROR') {
            this.msgAlert = response[0].message
            return
          } 
        }
        this.items.splice(this.dispositivoIndex, 1, item)
        this.showModalAlias = false
      } catch (e) {
        this.loading = false
        this.msgAlert = this.$t('errors.networkError')
      }
    },
    newItem () {
      this.setDispositivoSeleccionado(null)
      this.$router.push("/organizations/assign")
    },
    // eslint-disable-next-line no-unused-vars
    async configuration (item, index, event) {
      this.setDispositivoSeleccionado(item)
      const accionesDispositivo = await this.$api.ejecutar("ACCIONES_DISPOSITIVO_SEL", [this.usuario.token, this.dispositivoSeleccionado.id_dispositivo])
      if (Array.isArray(accionesDispositivo) && accionesDispositivo.length > 0) {
        this.setAccionSeleccionada(accionesDispositivo[0])
      } else { // es un dispositivo nuevo
        this.setAccionSeleccionada(this.itemsAcciones[0])
      }
      this.showAcciones = true
      // this.$router.push("/devices/configuration")
    },
    seleccionaAccion (accion) {
      this.setAccionSeleccionada(accion)
      this.showAcciones = false
      this.$router.push("/devices/configuration")
    },
    alias (item, index, event) {
      this.setDispositivoSeleccionado(item)
      // this.$router.push("/devices/alias")
      this.dispositivo = { ...item }
      this.dispositivoIndex = index
      this.showModalAlias = true
      this.$nextTick(() => {
        // debugger
        this.$refs.inputAlias.focus()
      })
    },
    descargarQR (dispositivo) {
      // nothing
      const link = document.createElement('a')
      link.href = dispositivo.srcQR
      link.download = `${dispositivo.id_dispositivo}.png`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    copiarQR (dispositivo) {
      copyToClipboard(`${this.urlAcciones}/${dispositivo.id_dispositivo}`)
    },
  },
}
</script>
<style>
@media only screen and (max-width: 415px) {
  #sidebar-acciones {
    width: 120% !important;
  }
}
.sidebar-acciones-full {
  width: 120% !important;
}
#sidebar-acciones___title__ {
  width: 100% !important;
}
</style>
