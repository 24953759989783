import Vue from 'vue'
import App from './App.vue'
import './plugins/nprogress'
import './plugins/axios'
import './plugins/mq'
import i18n from './plugins/i18n'
import './plugins/api'
import './plugins/bootstrap-vue'
import './plugins/vue-tel-input'
import router from './router'
import store from './store'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
