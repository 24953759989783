/* eslint-disable no-shadow */
const state = {
  usuarioSeleccionado: null,
  usuarioSeleccionadoIndex: null,
}
const getters = {
  usuarioSeleccionado: (state) => state.usuarioSeleccionado,
  usuarioSeleccionadoIndex: (state) => state.usuarioSeleccionadoIndex,
}

const mutations = {
  setUsuarioSeleccionado: (state, usuario) => {
    state.usuarioSeleccionado = usuario
  },
  setUsuarioSeleccionadoIndex: (state, index) => {
    state.usuarioSeleccionadoIndex = index
  },
}

const actions = {
}

const moduloUsuarios = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
export default moduloUsuarios
