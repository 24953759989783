<template>
      <!-- Start content -->
  <div class="content">
    <div class="container-fluid">
      <div class="page-title-box">
        <div class="row align-items-center">
          <div class="col-sm-12 col-md-10">
            <h4 class="page-title">{{ $t('devices.preview') }}</h4>
          </div>
          <div class="col-sm-12 col-md-2">
            <b-button
             class="float-right"
             size="md"
             :block="$mq === 'mobile'"
             @click="$router.push('/devices/configuration')"
             >
              <i class="fa fa-arrow-left mr-2"></i>{{ $t('global.back') }}
            </b-button>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end page-title -->
      <div class="embed-responsive embed-responsive-16by9 border-1" style="height: 70vh;" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow rounded' : 'shadow rounded'">
        <iframe id="previewWindow" class="embed-responsive-item" :src="`${urlAcciones}/${dispositivoSeleccionado.id_dispositivo}?preview=true`">
        </iframe>
      </div>
    </div>
    <!-- container-fluid -->
  </div>
  <!-- content -->
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      temaVisual: 'getTemaVisual',
      lenguaje: 'getLenguaje',
      usuarioLogged: 'getUsuario',
      parametrosGenerales: 'getParametrosGenerales',
      urlImagenes: 'getUrlImagenes',
      urlAcciones: 'getUrlAcciones',
      urlLogo: 'getUrlLogo',
      urlLogoDark: 'getUrlLogoDark',
    }),
    ...mapGetters("dispositivos", [
      "dispositivoSeleccionado",
      "dispositivoSeleccionadoIndex",
    ]),
  },
}
</script>
