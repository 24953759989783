<template>
        <!-- Start content -->
  <div class="content">
    <div class="container-fluid">
      <div class="page-title-box">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <h4 class="page-title">{{ $t('devices.aliasTitle') }}</h4>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end page-title -->
    <!-- <b-card no-body class="overflow-hidden p-2 col-xs-12 col-sm-12 col-md-6 col-lg-4" :class="temaVisual === 'dark' ? 'is-dark text-muted shadow rounded' : 'shadow rounded'" style="border-radius: 15px !important;">
      <b-row>
        <b-col md="12">
          <b-row>
            <b-col>
              <img :src="temaVisual === 'dark' ? urlLogoDark : urlLogo" alt="BTap" class="rounded-0 p-1" width="100">
            </b-col>
          </b-row>
          <b-row>
            <b-col> -->
              <!-- <h5>{{ dispositivo.alias ? dispositivo.alias : dispositivo.id_dispositivo }}</h5> -->
              <!-- <h5>{{ dispositivo.id_dispositivo }}</h5>
              
              <h6>{{ dispositivo.id_accion_principal ? $t(`devices.actionsLabels.${dispositivo.id_accion_principal}`) : ''}}</h6>
            </b-col>
          </b-row>
        </b-col>
      </b-row> -->
      <!-- <b-row>
        <b-col sm="12">
        <i class="fa fa-clipboard fa-2x mr-3 float-right text-btap-primary" v-b-tooltip.hover :title="$t('devices.copyURL')" @click="copiarQR(dispositivo)"></i>
        </b-col>
      </b-row> -->
    <!-- </b-card>  -->
    <!-- <b-card
      no-body
      class="shadow mr-2 ml-2"
      :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : ''"
    >
      <b-card-body>
        <b-container fluid>
          <b-row>
            <b-col md="6" sm="12">
              <b-col sm="12">
                
              </b-col>

            </b-col>

          </b-row>   
        </b-container>
      </b-card-body>
    </b-card> -->
    <div class="callout">
      
        <b-form-input
                class="mb-3 ml-1 mr-2"
                :class="temaVisual === 'dark' ? 'is-dark text-muted shadow-none' : 'is-light bg-transparent'"
                name="input-alias"
                id="input-alias"
                type="text"
                size="sm"
                :placeholder="$t('devices.alias')"
                v-model="dispositivo.alias"
              >
              </b-form-input>
        <b-button 
            class="mr-2" 
            :loading="loading" 
            :disabled="!valid || valid === false || loading" 
            :class="!valid || valid === false || loading ? 'text-muted' : ''" 
            :block="$mq === 'mobile'"
            @click="updateAlias(dispositivo)"
            variant="primary">
            <b-icon icon="upload" class="font-weight-bolder mr-2"></b-icon>&nbsp;{{ $t('global.update') }}
        </b-button>
        <b-button 
            @click="back" 
            type="warning" 
            :icon="true"
            :block="$mq === 'mobile'">
            <b-icon icon="backspace" class="font-weight-bolder mr-2"></b-icon>&nbsp;{{ $t('global.cancel') }}
        </b-button>
        <!-- </center> -->
        <b-alert
            :show="!!msgAlert && msgAlert.length > 0"
            variant="danger"
            dismissible
            fade
            >{{ msgAlert }}</b-alert
          >

    </div>
    
  </div>
    <!-- container-fluid -->
  </div>
  <!-- content -->
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { nanoid } from "nanoid"
import QRCode from "qrcode"

let vueRef = null
const dispositivoInicial = {
  id_dispositivo: null,
  id_empresa: null,
  id_tipo_dispositivo: null,
  es_masivo: "N",
  cantidad_dispositivos: 1,
  estado: "A",
}

const copyToClipboard = (str) => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export default {
  data () {
    return {
      valid: true,
      loading: false,
      dispositivo: {},
      accion: '',
      show: true,
      msgAlert: "",
      srcQR: "https://via.placeholder.com/150",
      itemsTiposDispositivo: [],
      itemsAcciones: [],
    }
  },
  computed: {
    ...mapGetters({
      temaVisual: "getTemaVisual",
      usuarioAuth: "getUsuario",
      parametrosGenerales: "getParametrosGenerales",
      urlImagenes: "getUrlImagenes",
      urlLogo: "getUrlLogo",
      urlLogoDark: "getUrlLogoDark",
      urlAcciones: "getUrlAcciones",
    }),
    ...mapGetters("empresas", [
      "empresaSeleccionada",
      "empresaSeleccionadaIndex",
    ]),
    ...mapGetters("dispositivos", [
      "dispositivoSeleccionado",
      "dispositivoSeleccionadoIndex",
    ]),
  },
  async mounted () {
    vueRef = this
    this.loading = true
    this.itemsTiposDispositivo = await this.$api.ejecutar("CATALOGOS", [
      "TIPOS_DISPOSITIVO",
    ])
    this.itemsAcciones = await this.$api.ejecutar("CATALOGOS", [
      "ACCIONES",
    ])
    if (
      !!this.dispositivoSeleccionado
      && this.dispositivoSeleccionado !== null
    ) {
      this.dispositivo = {
        ...JSON.parse(JSON.stringify(this.dispositivoSeleccionado)),
      }
      const accionesDispositivo = await this.$api.ejecutar("ACCIONES_DISPOSITIVO_SEL", [this.usuarioAuth.token, this.dispositivo.id_dispositivo])
      if (Array.isArray(accionesDispositivo) && accionesDispositivo.length > 0) {
        this.accion = accionesDispositivo[0].id_accion
      } else { // es un dispositivo nuevo
        this.accion = this.itemsAcciones[0].id_accion
      }
    } else {
      this.dispositivo = { ...dispositivoInicial }
      this.dispositivo.id_dispositivo = nanoid()
      this.dispositivo.id_empresa = this.empresaSeleccionada.id_empresa
      if (this.itemsTiposDispositivo.length > 0) {
        this.dispositivo.id_tipo_dispositivo = this.itemsTiposDispositivo[0].id_tipo_dispositivo
      }
    }
    this.srcQR = await QRCode.toDataURL(
      `${this.urlAcciones}/${this.dispositivo.id_dispositivo}`,
      { width: 512 },
    )
    this.loading = false
  },
  methods: {
    ...mapMutations("dispositivos", ["setDispositivoSeleccionado"]),
    async updateAlias (item) {
      // console.log('updating alias ')
      this.loading = true
      try {
        const params = [
          this.usuarioAuth.token,
          item.id_dispositivo,
          item.alias,
        ]
        const response = await this.$api.ejecutar('actualiza_alias', params)
        this.loading = false
        if (response.length > 0) {
          if (response[0].status !== null && response[0].status === 'ERROR') {
            this.msgAlert = response[0].message
            return
          } 
        }
        this.back()
      } catch (e) {
        this.loading = false
        this.msgAlert = this.$t('errors.networkError')
      }
    },
    back () {
      this.setDispositivoSeleccionado(null)
      // this.$router.push("/devices")
      this.$emit('back')
    },
  },
}
</script>
