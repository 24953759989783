import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '../locales/en.json'
import es from '../locales/es.json'

Vue.use(VueI18n)

const i18n = new VueI18n({ // construction a new VueI18n
  locale: 'en', // store.state.locale,
  fallbackLocale: 'es', // always displays ES if English translation is not available
  messages: {
    // 'locales' directory contains all the translations in the form of json files
    // eslint-disable-next-line quote-props
    'en': en,
    // eslint-disable-next-line quote-props
    'es': es,
  },
})

export default i18n
