/* eslint-disable no-shadow */
const state = {
  dispositivoSeleccionado: null,
  dispositivoSeleccionadoIndex: null,
  accionSeleccionada: null,
}
const getters = {
  accionSeleccionada: (state) => state.accionSeleccionada,
  dispositivoSeleccionado: (state) => state.dispositivoSeleccionado,
  dispositivoSeleccionadoIndex: (state) => state.dispositivoSeleccionadoIndex,
}

const mutations = {
  setDispositivoSeleccionado: (state, dispositivo) => {
    state.dispositivoSeleccionado = dispositivo
  },
  setDispositivoSeleccionadoIndex: (state, index) => {
    state.dispositivoSeleccionadoIndex = index
  },
  setAccionSeleccionada: (state, accion) => {
    state.accionSeleccionada = accion
  },
}

const actions = {
}

const moduloDispositivos = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
export default moduloDispositivos
