// import AuthLayout from '@/layout/AuthLayout.vue'
// import MainLayout from '@/layout/main/MainLayout.vue'
import Login from '@/pages/Login.vue'
import LoginRecover from '@/pages/LoginRecover.vue'
import Register from '@/pages/Register.vue'
import ErrorPage from '@/pages/Error.vue'
import Dashboard from '@/pages/Dashboard.vue'
import Profile from '@/pages/Profile.vue'
import MainLayout from '@/layout/Main.vue'
import Devices from '@/pages/Devices/Devices.vue'
import Configuration from '@/pages/Devices/Configuration.vue'
import Alias from '@/pages/Devices/Alias.vue'
import Preview from '@/pages/Devices/Preview.vue'

const routes = [ 
  {                        
    path: '/home',         
    name: 'home',
    component: MainLayout,
    children: [
      {
        path: '/profile',
        name: 'profile',
        meta: {
          requiresAuth: true,
          icon: 'graph-up',
        },
        component: Profile,
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
          requiresAuth: true,
          icon: 'graph-up',
        },
        component: Dashboard,
      },
      {
        path: '/devices',
        name: 'devices',
        meta: {
          requiresAuth: true,
          icon: 'devices',
        },
        component: Devices,
      },
      {
        path: '/devices/configuration',
        name: 'devicesConfiguration',
        meta: {
          requiresAuth: true,
          icon: 'devicesConfiguration',
        },
        component: Configuration,
      },
      {
        path: '/devices/alias',
        name: 'devicesAlias',
        meta: {
          requiresAuth: true,
          icon: 'devicesConfiguration',
        },
        component: Alias,
      },
      {
        path: '/devices/preview',
        name: 'devicesPreview',
        meta: {
          requiresAuth: true,
          icon: 'devicesConfiguration',
        },
        component: Preview,
      },
    ],
  },          
  {
    path: '/',
    alias: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/register/:id',
    name: 'register',
    component: Register,
  },
  {
    path: '/loginrecover/:token',
    name: 'loginrecover',
    component: LoginRecover,
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage,
  },
]

export default routes
