/* eslint-disable no-shadow */
import Vue from 'vue'
import Vuex from 'vuex'

// se utiliza un modulo por pantalla
import moduloUsuarios from './modules/usuarios'
import moduloTiposDispositivo from './modules/tiposDispositivo'
import moduloEmpresas from './modules/empresas'
import moduloDispositivos from './modules/dispositivos'

const state = {
  usuario: null,
  busquedaGeneral: '',
  parametrosGenerales: {},
}

const getters = {
  getTemaVisual: (state) => {
    if (!!state.usuario && state.usuario !== null) {
      return state.usuario.tema
    } 
    return 'light'
  },
  getLenguaje: (state) => {
    if (!!state.usuario && state.usuario !== null) {
      return state.usuario.lenguaje
    } 
    return 'es'
  },
  getUrlImagenes: (state) => {
    if (!!state.parametrosGenerales && state.parametrosGenerales !== null && state.parametrosGenerales.url_servidor_archivos) {
      return state.parametrosGenerales.url_servidor_archivos
    } 
    return ''
  },
  getUrlAcciones: (state) => {
    if (!!state.parametrosGenerales && state.parametrosGenerales !== null && state.parametrosGenerales.url_acciones) {
      return state.parametrosGenerales.url_acciones
    } 
    return ''
  },
  getUrlLogo: (state) => {
    if (!!state.parametrosGenerales && state.parametrosGenerales !== null && state.parametrosGenerales.url_logo) {
      return state.parametrosGenerales.url_logo
    } 
    return ''
  },
  getUrlLogoDark: (state) => {
    if (!!state.parametrosGenerales && state.parametrosGenerales !== null && state.parametrosGenerales.url_logo_dark) {
      return state.parametrosGenerales.url_logo_dark
    } 
    return ''
  },
  getUrlClave: (state) => {
    if (!!state.parametrosGenerales && state.parametrosGenerales !== null && state.parametrosGenerales.url_clave) {
      return state.parametrosGenerales.url_clave
    } 
    return ''
  },
  getUsuario: (state) => state.usuario,
  getParametrosGenerales: (state) => state.parametrosGenerales,
  allState: (state) => state,
}
  
const mutations = {
  setTemaVisual: (state, temaVisual) => {
    state.usuario.tema = temaVisual
  },
  setLenguaje: (state, lenguaje) => {
    state.usuario.lenguaje = lenguaje
  },
  setBusquedaGeneral: (state, texto) => {
    state.busquedaGeneral = texto
  },
  limpiarBusquedaGeneral: (state) => {
    state.busquedaGeneral = ''
  },
  setUsuario: (state, usuario) => {
    state.usuario = usuario
  },
  setParametrosGenerales: (state, parametros) => {
    state.parametrosGenerales = parametros
  },
}

const actions = {
}

const modules = {
  usuarios: moduloUsuarios,
  tiposDispositivo: moduloTiposDispositivo,
  empresas: moduloEmpresas,
  dispositivos: moduloDispositivos,
}

const plugins = [
]

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules,
  plugins,
})
